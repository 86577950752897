import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { getSurveyAnswers } from 'src/actionCreators/surveyAnalysisActionCreators';
import granteeSurveysApi from 'src/api/GranteeSurveys';
import {
  Actions,
  Card,
  Container,
  CardEmpty,
  Section,
  Progress,
  FilterTrigger,
  Filter,
  SelectPopoverLight,
} from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import history from 'src/historyInstance';
import { getText } from 'src/services/DictionaryService';
import { containsText } from 'src/utils/string';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import SummaryItem from './SummaryItem';
import {
  getOptionsForQuestion,
  resolveMatcher,
  getResponsesForQuestionOptions,
} from './surveyAnalysisUtils';
import surveyCalculationsApi, {
  SurveyCalculations,
} from 'src/api/SurveyCalculations';
import emptyAnalysis from 'src/static/empty_analysis.png';
import cls from './Summary.module.css';
import { surveyOnly } from 'src/userStorage';
const ACTIVE_QUESTION_PATH = 'question';
const COMPARE_QUESTION_PATH = 'compareWith';

@connect(pick(['survey', 'granteeSurveysSummary', 'cachedSurveyAnswers']), {
  getSurveyAnswers,
  findAllGranteeSurveys: granteeSurveysApi.summary.findAll,
  getTagsForQuestion: surveyCalculationsApi.tagsForQuestion,
  getTagsForQuestionBySeries: surveyCalculationsApi.tagsForQuestionBySeries,
  clearTagsDataFromStore: SurveyCalculations.actions.clear,
})
export default class SurveySummary extends React.PureComponent {
  static propTypes = {
    ...ReactRouterPropTypes,
    getSurveyAnswers: PropTypes.func.isRequired,
    findAllGranteeSurveys: PropTypes.func.isRequired,
    granteeSurveysSummary: PropTypes.object,
    cachedSurveyAnswers: PropTypes.object.isRequired,
    survey: PropTypes.object,
    getTagsForQuestion: PropTypes.func,
    getTagsForQuestionBySeries: PropTypes.func,
    clearTagsDataFromStore: PropTypes.func,
    activeStepId: PropTypes.number,
  };

  state = {
    activeQuestion: 0,
    compareQuestion: -1,
    filters: [],
    showFilters: false,
  };

  componentDidMount() {
    const urlQuestionNumber = location.search.match(
      new RegExp(`${ACTIVE_QUESTION_PATH}=(\\d+|[a-z_]+)`)
    );
    const compareWithQuestionQuery = location.search.match(
      new RegExp(`${COMPARE_QUESTION_PATH}=(\\d+|[a-z_]+)`, 'i')
    );
    let activeIndex = this.state.activeQuestion;
    if (urlQuestionNumber) {
      activeIndex = urlQuestionNumber[1].match(/[a-z_]+/)
        ? urlQuestionNumber[1]
        : +urlQuestionNumber[1] - 1;
    }
    let compareIndex = this.state.compareQuestion;
    if (compareWithQuestionQuery) {
      compareIndex = compareWithQuestionQuery[1].match(/[a-z_]+/)
        ? compareWithQuestionQuery[1]
        : +compareWithQuestionQuery[1] - 1;
    }
    this.handleActiveChanged(activeIndex, compareIndex);

    const showUnfinished = location.search.includes('unfinished');
    const query = where()
      .filter(showUnfinished ? '' : 'is_finished_eq', true)
      .filter('survey_id_eq', this.props.match.params.surveyId)
      .paginate({ size: 10000 });
    this.props.findAllGranteeSurveys(query).then(() => {
      if (this.props.survey.id != this.props.match.params.surveyId) return;
      const activeQuestionId = this.props.survey.questions.filter(
        (item) => !item.isLayout
      )?.[this.state.activeQuestion]?.id;
      if (activeQuestionId) {
        this.props.getSurveyAnswers(activeQuestionId);
      }
    });
  }

  getQuestionOptions = (currItem) => {
    const question = this.props.survey.questions.find(
      (q) => q.id === +currItem.matcher
    );
    if (!question || !this.props.cachedSurveyAnswers[question.id]) {
      return [];
    }
    return getOptionsForQuestion(
      question,
      this.props.cachedSurveyAnswers[question.id].data,
      this.props.granteeSurveysSummary.data
    ).map((option) => ({ name: option, displayName: option }));
  };

  get filterConfig() {
    const vals = {
      grant_name: [],
      grantee_name: [],
      respondent_name: [],
      respondent_email: [],
      batch: [],
      language: [],
    };
    this.props.granteeSurveysSummary.data.forEach((gs) =>
      Object.keys(vals).forEach((key) => vals[key].push(gs[key]))
    );
    Object.keys(vals).forEach((valueKey) => {
      vals[valueKey] = vals[valueKey]
        .filter((val, index, arr) => {
          return !!val && arr.indexOf(val) === index;
        })
        .map((val) => ({ name: val, displayName: val }))
        .sort();
    });
    const qMatchers = this.props.survey.questions
      .filter((q) => !q.isLayout)
      .reduce((acc, q) => {
        acc[q.id] = q.question;
        return acc;
      }, {});
    return [
      {
        name: 'question',
        displayName: 'Question',
        matchers: qMatchers,
        type: 'select',
        clearResultsOnMatcherChange: true,
        getValues: this.getQuestionOptions,
      },
      surveyOnly()
        ? null
        : {
            name: 'grant_name',
            displayName: getText('Grant Name'),
            type: 'select',
            values: vals.grant_name,
          },
      surveyOnly()
        ? null
        : {
            name: 'grantee_name',
            displayName: getText('Grantee Name'),
            type: 'select',
            values: vals.grantee_name,
          },
      {
        name: 'respondent_name',
        displayName: 'Respondent Name',
        type: 'select',
        values: vals.respondent_name,
      },
      {
        name: 'respondent_email',
        displayName: 'Respondent Email',
        type: 'select',
        values: vals.respondent_email,
      },
      {
        name: 'batch',
        displayName: 'Series',
        type: 'select',
        values: vals.batch,
      },
      {
        name: 'language',
        displayName: 'Language',
        type: 'select',
        values: vals.language,
      },
      { name: 'notified_at', displayName: 'Notification Date', type: 'date' },
      { name: 'finished_at', displayName: 'Submission Date', type: 'date' },
    ].filter(Boolean);
  }

  handleAnalysisFilter = (filters) => {
    this.setState({ filters });
  };

  handleFilterChanged = (filters) => {
    if (!Object.keys(filters)?.length) return;
    Object.values(filters).forEach((filter) => {
      if (
        filter.variable === 'question' &&
        filter.matcher &&
        !this.props.cachedSurveyAnswers?.[+filter.matcher]
      ) {
        this.props.getSurveyAnswers(
          this.props.survey.questions.find((q) => q.id == filter.matcher)?.id
        );
      }
    });
  };

  handleClearAnalysisFilter = (rm) => {
    this.setState({
      filters: this.state.filters.filter((f) =>
        (Array.isArray(rm) ? rm : [rm]).some(
          (rf) => !Object.keys(f).every((key) => f[key] === rf[key])
        )
      ),
    });
  };
  handleToggleAnalysisFilter = (showFilters) => {
    this.setState({
      showFilters:
        typeof showFilters === 'boolean'
          ? showFilters
          : !this.state.showFilters,
    });
  };
  handleNextQuestion = () => {
    this.handleActiveChanged(
      typeof this.state.activeQuestion === 'string'
        ? 0
        : this.state.activeQuestion + 1
    );
  };
  handlePrevQuestion = () => {
    this.handleActiveChanged(
      typeof this.state.activeQuestion === 'string'
        ? 0
        : this.state.activeQuestion - 1
    );
  };

  handleActiveChanged = (index, compareIndex) => {
    let compareQuestion =
      typeof compareIndex === 'number' || typeof compareIndex === 'string'
        ? compareIndex
        : this.state.compareQuestion;
    const questions = this.props.survey.questions.filter((q) => !q.isLayout);
    let activeQuestion = index?.value || index;
    if (typeof activeQuestion === 'string' && activeQuestion.match(/^\d+/))
      activeQuestion = Math.max(
        0,
        Math.min(activeQuestion, questions.length - 1)
      );
    if (typeof compareQuestion === 'string' && compareQuestion.match(/^\d+/))
      compareQuestion = Math.max(
        -1,
        Math.min(compareQuestion, questions.length - 1)
      );
    this.setState({ activeQuestion, compareQuestion });

    if (this.props.survey.id != this.props.match.params.surveyId) return;
    const activeQuestionModel =
      typeof activeQuestion === 'string' ? null : questions[activeQuestion];
    const compareQuestionModel =
      +compareQuestion > -1 ? questions[compareQuestion] : null;

    if (activeQuestionModel?.id) {
      this.props.getSurveyAnswers(activeQuestionModel.id);
      this.props.clearTagsDataFromStore();
      this.props.getTagsForQuestion({
        surveyQuestionId: activeQuestionModel.id,
        surveyTitle: this.props.survey.name,
      });
      this.props.getTagsForQuestionBySeries({
        surveyQuestionId: activeQuestionModel.id,
        surveyTitle: this.props.survey.name,
      });
    }
    if (compareQuestionModel?.id) {
      this.props.getSurveyAnswers(compareQuestionModel.id);
      this.props.clearTagsDataFromStore();
      this.props.getTagsForQuestion({
        surveyQuestionTitle: compareQuestionModel.question,
        surveyTitle: this.props.survey.name,
      });
      this.props.getTagsForQuestionBySeries({
        surveyQuestionTitle: compareQuestionModel.question,
        surveyTitle: this.props.survey.name,
      });
    }

    let compareQuery;
    if (typeof compareQuestion === 'string') {
      compareQuery = compareQuestion;
    } else if (compareQuestion > -1) {
      compareQuery = compareQuestion + 1;
    }

    const potentialUrl = `?${ACTIVE_QUESTION_PATH}=${activeQuestion + 1}${
      compareQuery ? `&${COMPARE_QUESTION_PATH}=${compareQuery}` : ''
    }`;
    if (location.search != potentialUrl)
      history.replace({ search: potentialUrl });
  };

  handleCompareChanged = (index) => {
    this.handleActiveChanged(this.state.activeQuestion, index);
  };
  handleSwapQuestions = () => {
    this.handleActiveChanged(
      this.state.compareQuestion,
      this.state.activeQuestion
    );
  };

  filterRespondents() {
    if (!this.state.filters.length)
      return this.props.granteeSurveysSummary.data.slice();
    const { question, ...groupedFilters } = this.state.filters.reduce(
      (acc, el) => {
        acc[el.variable] ||= [];
        acc[el.variable].push(el);
        return acc;
      },
      {}
    );
    const filteredRespondents = this.props.granteeSurveysSummary.data.filter(
      (gs) =>
        Object.values(groupedFilters).every((fg) =>
          fg.some((f) => resolveMatcher(gs[f.variable], f))
        )
    );
    if (!question?.length) return filteredRespondents;

    const matchedIds = [];
    const qfgs = Object.values(
      question.reduce((acc, el) => {
        acc[el.matcher] ||= [];
        acc[el.matcher].push(el);
        return acc;
      }, {})
    );
    qfgs.forEach((qfg) => {
      const sq = this.props.survey.questions.find(
        (q) => q.id === Number(qfg[0].matcher)
      );
      if (!sq) return;
      matchedIds.push(
        getResponsesForQuestionOptions(
          sq,
          qfg.map((qf) => qf.result),
          this.props.cachedSurveyAnswers[sq.id]?.data
        )?.map((id) => Number(id))
      );
    });
    if (matchedIds.length === 1)
      return filteredRespondents.filter(
        (gs) => matchedIds[0].indexOf(Number(gs.id)) !== -1
      );

    // we now have all matching response ids goruped by question only the IDs that appear in all groups satisfy AND rule
    const flattenedIds = matchedIds.reduce(
      (acc, idsArr) => [...acc, ...idsArr],
      []
    );
    if (!matchedIds.length || !flattenedIds.length) return filteredRespondents;

    const groupedFlattenIds = flattenedIds.reduce((acc, id) => {
      if (!acc[id]) {
        acc[id] = 0;
      }
      acc[id] += 1;
      return acc;
    }, {});
    return filteredRespondents.filter(
      (gs) =>
        groupedFlattenIds[Number(gs.id)] &&
        groupedFlattenIds[Number(gs.id)] === qfgs.length
    );
  }

  renderQuestionSelect(
    value,
    questions,
    onChange,
    allowEmpty,
    anchor = 'left'
  ) {
    const suffixOptions = [
      ['grant_name', getText('Grant name')],
      ['grantee_name', getText('Grantee name')],
    ];
    const renderItems = (searchText) => {
      const items = [];
      if (allowEmpty) {
        items.push(
          <SelectPopoverLight.MenuItem
            key={-1}
            leftIcon={
              <Icon
                name="check"
                color={value !== -1 ? colors['light-grey'] : colors.seafoam}
              />
            }
            primaryText="None"
            onClick={() => onChange(-1)}
            style={{ fontSize: 13 }}
          />
        );
      }

      questions
        .map((q, index) => ({ q, index }))
        .filter(({ q }) => containsText(q.question, searchText))
        .forEach(({ q, index }) =>
          items.push(
            <SelectPopoverLight.MenuItem
              key={index}
              leftIcon={
                <Icon
                  name="check"
                  color={
                    value !== index ? colors['light-grey'] : colors.seafoam
                  }
                />
              }
              primaryText={`${
                q.question?.match(/^\d/) ? '' : `${index + 1}. `
              }${q.question}`}
              onClick={() => onChange(index)}
              style={{ fontSize: 13 }}
            />
          )
        );

      items.push(<SelectPopoverLight.Divider key="divider" compact />);

      suffixOptions.forEach((so) =>
        items.push(
          <SelectPopoverLight.MenuItem
            key={so[0]}
            primaryText={getText(so[1])}
            leftIcon={
              <Icon
                name="check"
                color={value !== so[0] ? colors['light-grey'] : colors.seafoam}
              />
            }
            onClick={() => onChange(so[0])}
            style={{ fontSize: 13 }}
          />
        )
      );

      return items;
    };

    const label =
      questions[value]?.question ||
      suffixOptions.filter((so) => so[0] === value)[0]?.[1] ||
      'None';
    const popoverProps = {
      searchHint: 'Find question',
      largeWidth: !allowEmpty,
      large: false,
      customWidth: 768,
      style: { width: 768, maxWidth: 768 },
    };
    if (anchor === 'right') {
      popoverProps.anchorOrigin = { horizontal: 'right', vertical: 'top' };
      popoverProps.targetOrigin = popoverProps.anchorOrigin;
    }
    return (
      <SelectPopoverLight
        label={label}
        popoverProps={popoverProps}
        renderItems={renderItems}
      />
    );
  }

  renderAnswersAnaysis() {
    if (
      this.props.survey.pending ||
      this.props.granteeSurveysSummary.pending.init
    ) {
      return (
        <Section>
          <Progress />
        </Section>
      );
    }

    const questions = this.props.survey.questions.filter((q) => !q.isLayout);
    const question = questions[this.state.activeQuestion];
    const questionToCompare = questions[this.state.compareQuestion];
    const isFetching =
      (question && !this.props.cachedSurveyAnswers[question.id]) ||
      this.props.cachedSurveyAnswers.pending ||
      (questionToCompare &&
        typeof questionToCompare !== 'string' &&
        !this.props.cachedSurveyAnswers[questionToCompare.id]);
    const filteredResponses = this.filterRespondents();
    const cannotSwap =
      this.state.compareQuestion == -1 ||
      typeof +this.state.compareQuestion !== 'number';

    return (
      <div style={{ maxWidth: 1540, width: '100%', margin: '0 auto' }}>
        <Section key={`${question?.id}.${questionToCompare?.id}`} collapsed>
          <Container horizontal className={cls.filters}>
            <FilterTrigger
              active={this.state.showFilters}
              filters={this.state.filters}
              config={this.filterConfig}
              onToggle={this.handleToggleAnalysisFilter}
            />
            <span>&emsp;</span>
            <Actions nowrap>
              {questions.length >= 2 && (
                <Icon
                  name="arrow-left"
                  disabled={this.state.activeQuestion === 0}
                  onClick={this.handlePrevQuestion}
                />
              )}
              {questions.length >= 1 &&
                this.renderQuestionSelect(
                  this.state.activeQuestion,
                  questions,
                  this.handleActiveChanged,
                  false
                )}
              {questions.length >= 2 && (
                <Icon
                  name="arrow-right"
                  disabled={this.state.activeQuestion === questions.length - 1}
                  onClick={this.handleNextQuestion}
                />
              )}
            </Actions>
            <Actions nowrap>
              {questions.length >= 2 && (
                <Icon
                  name="compare"
                  tip="Swap questions"
                  disabled={cannotSwap}
                  style={{ pointerEvents: cannotSwap ? 'none' : 'auto' }}
                  onClick={this.handleSwapQuestions}
                />
              )}
              {questions.length >= 2 &&
                this.renderQuestionSelect(
                  this.state.compareQuestion,
                  questions,
                  this.handleCompareChanged,
                  true,
                  'right'
                )}
            </Actions>
          </Container>

          <br />
          <Container horizontal className={cls.filters}>
            <Filter
              fullWidth
              showCreator={this.state.showFilters}
              filters={this.state.filters}
              config={this.filterConfig}
              onFilterChanged={this.handleFilterChanged}
              onToggleCreator={this.handleToggleAnalysisFilter}
              onClear={this.handleClearAnalysisFilter}
              onSubmit={this.handleAnalysisFilter}
            />
          </Container>
        </Section>

        <Section>
          {isFetching && <Progress large className="absolute" />}
          <SummaryItem
            key={this.props.cachedSurveyAnswers[question?.id]?.created_at} // key ensures that every cache reload updates the answers table
            responses={filteredResponses}
            question={
              typeof this.state.activeQuestion === 'string'
                ? this.state.activeQuestion
                : question
            }
            questionToCompare={
              typeof this.state.compareQuestion === 'string'
                ? this.state.compareQuestion
                : questionToCompare
            }
            answers={this.props.cachedSurveyAnswers[question?.id]?.data}
            answersToCompare={
              this.props.cachedSurveyAnswers[questionToCompare?.id]?.data
            }
            projectId={this.props.match.params.projectId}
          />
        </Section>
      </div>
    );
  }

  renderNoData() {
    return (
      <Section>
        <Card normalPadding>
          <Container centerHorizontal horizontal>
            <div>
              <img
                src={emptyAnalysis}
                alt="emptyAnalysis"
                style={{ marginTop: 80, width: 400, height: 375 }}
              />
            </div>
          </Container>
          <CardEmpty
            large
            title="No survey has been submitted yet"
            style={{ height: 80, marginTop: 40, marginBottom: 40 }}
          />
        </Card>
      </Section>
    );
  }
  render() {
    const noData =
      this.props.granteeSurveysSummary.data?.length == 0 &&
      !this.props.survey.pending &&
      !this.props.granteeSurveysSummary.pending?.init;
    return noData ? this.renderNoData() : this.renderAnswersAnaysis();
  }
}
