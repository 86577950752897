import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import { changeSurveyProperty } from 'src/actionCreators/surveyActionCreators';
import contactsApi from 'src/api/Contacts';
import surveyRespondentsApi, {
  surveyRespondentsGetAdvancedFilters,
} from 'src/api/SurveyRespondents';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import {
  Actions,
  Button,
  Card,
  CardEmpty,
  Section,
  Container,
  Checkbox,
  Table,
  Progress,
  CardFooter,
  PopoverLight,
  FilterJsonapi,
  FilterTrigger,
  Divider,
} from 'src/components/IMUI';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import Warning from 'src/components/Warning/Warning';
import { getText } from 'src/services/DictionaryService';
import { isGrantless } from 'src/utils/analysis';
import { isBefore, toLocaleDate, toLocaleDateTime } from 'src/utils/date';
import { getI18nCompleteness } from 'src/utils/surveysI18n';
import noResponses from 'src/static/empty_responses.png';
import { where } from 'im/api/Query';
import QueryParamsManager from 'im/api/QueryParamsManager';
import { Icon } from 'im/ui/Icon';

import EditContactModal from '../components/EditContactModal';

import ContactCSVUploadButton from './components/ContactCSVUploadButton';
import CreateContactsModal from './components/CreateContactsModal';
import ImportContactsModal from './components/ImportContactsModal';
import SettingAnonymous from './components/SettingAnonymous';
import SettingBatch from './components/SettingBatch';
import SettingDeadline from './components/SettingDeadline';
import SettingPublic from './components/SettingPublic';
import SettingsWrapper from './components/SettingsWrapper';
import ShareConfirmationModal from './components/ShareConfirmationModal';
import SurveyReminderModal from './components/SurveyReminderModal';
import WarningDialog from './components/WarningDialog';
import Stats from 'src/components/Stats/Stats';
import cls from './Share.module.css';
import { canManageSurvey, surveyOnly } from 'src/userStorage';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

@connect(
  (state) => ({
    project: state.project,
    survey: state.survey,
    surveyRespondents: state.surveyRespondents,
    grantContacts: state.grantContacts,
    granteeContacts: state.granteeContacts,
    advancedFilters: state.advancedFilters.surveyRespondents,
  }),
  {
    showNotification,
    changeSurveyProperty,
    findAllRespondents: surveyRespondentsApi.findAll,
    destroyRespondent: surveyRespondentsApi.destroy,
    createRespondent: surveyRespondentsApi.create,
    updateRespondent: surveyRespondentsApi.put,
    deliverRespondent: surveyRespondentsApi.deliver,
    remindRespondent: surveyRespondentsApi.remind,
    updateQuery: surveyRespondentsApi.findAll.updateQuery,

    findAllGranteeContacts: contactsApi.grantee.findAll,
    findAllGrantContacts: contactsApi.grant.findAll,

    granteeContactsUpdateQuery: contactsApi.grantee.findAll.updateQuery,
    grantContactsUpdateQuery: contactsApi.grant.findAll.updateQuery,
    ...surveyRespondentsGetAdvancedFilters,
  }
)
export default class SurveyShare extends Component {
  static propTypes = {
    ...ReactRouterPropTypes,
    project: PropTypes.object,
    survey: PropTypes.object,
    surveyRespondents: PropTypes.object,
    status: PropTypes.object,
    location: PropTypes.object,

    findAllRespondents: PropTypes.func,
    destroyRespondent: PropTypes.func,
    createRespondent: PropTypes.func,
    deliverRespondent: PropTypes.func,
    remindRespondent: PropTypes.func,
    updateQuery: PropTypes.func,

    granteeContactsUpdateQuery: PropTypes.func,
    grantContactsUpdateQuery: PropTypes.func,

    changeSurveyProperty: PropTypes.func,
    showNotification: PropTypes.func,
    findAllGranteeContacts: PropTypes.func,
    findAllGrantContacts: PropTypes.func,
    activeStepId: PropTypes.number,
  };

  static defaultProps = { project: {} };

  constructor(props) {
    super(props);

    this.state = {
      isTranslationComplete:
        !props.survey.pending && this.isTranslationComplete(props.survey),
      importDialogOpen: false,
      importDialogModel: null,
      createDialogOpen: false,
      reminderModalOpen: false,
      warningDialogOpen: false,
      shareDialogOpen: false,
      resendRespondent: null,
      selectedRespondents: {},
      respondentsToSubmit: [],
      anchorEl: null,
      popoverOpen: false,
      showFilterCreator: false,
      activeRespondent: null,
    };
  }

  componentDidMount() {
    const { survey } = this.props;
    const queryParamBatch = QueryParamsManager.get(
      this.props.location.search,
      'batch'
    );
    if (!survey.activeBatch && queryParamBatch) {
      this.props.changeSurveyProperty('activeBatch', queryParamBatch, {
        silent: true,
      });
    }
    this.findRespondentsByBatch(queryParamBatch || survey.activeBatch, {
      isInit: true,
    });

    const queryGrantee = where()
      .include('grantee')
      .sort('name')
      .filter('owner_type_eq', 'Grantee')
      .filter('project_id_eq', this.props.match.params.projectId);

    const queryGrant = where({ sort: 'name', include: 'grant' })
      .include('grant')
      .sort('name')
      .filter('owner_type_eq', 'Grant')
      .filter('project_id_eq', this.props.match.params.projectId);

    this.props.findAllGrantContacts(queryGrant);
    this.props.findAllGranteeContacts(queryGrantee);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.survey.id && !this.props.survey.id) ||
      nextProps.survey.questions !== this.props.survey.questions
    ) {
      this.setState({
        isTranslationComplete: this.isTranslationComplete(nextProps.survey),
      });
    }
  }

  get filterConfig() {
    return [
      { name: 'name', displayName: 'Name', type: 'text' },
      { name: 'email', displayName: 'Email', type: 'text' },
      surveyOnly()
        ? null
        : {
            name: 'grantee_name',
            displayName: getText('Grantee Name'),
            type: 'text',
          },
      surveyOnly()
        ? null
        : {
            name: 'grant_name',
            displayName: getText('Grant Name'),
            type: 'text',
          },
      { name: 'created_at', displayName: 'Added', type: 'date' },
      { name: 'notified_at', displayName: 'Notified', type: 'date' },
      { name: 'status', displayName: 'Status', type: 'select' },
      { name: 'finished_at', displayName: 'Submission Date', type: 'date' },
      {
        name: 'responded_at',
        displayName: 'Is Responded',
        type: 'boolean',
        matchers: { ['null']: 'is true' },
        transformMatcher: (_) => {
          return '';
        },
      },
      {
        name: 'grantee_survey_is_finished',
        displayName: 'Is Finished',
        type: 'boolean',
      },
    ].filter(Boolean);
  }

  get filterAutopopulationConfig() {
    const { advancedFilters } = this.props;

    return {
      allow: ['name', 'email', 'grantee_name', 'grant_name'],
      resource: advancedFilters,
      onRequestOptions: this.handleRequestFilterOptions,
    };
  }

  findRespondentsByBatch = (batchName, { isInit } = {}) => {
    const {
      match: { params },
      location: { search },
    } = this.props;
    let query = where({ survey_id: params.surveyId })
      .include('grant', 'grantee', 'grantee_survey')
      .sort(
        '-updated_at',
        '-created_at',
        'notified',
        '-notified_at',
        '-created_at'
      );
    if (isInit) {
      query.paginate({ number: 1, size: 100 });
      query.fromString(search);
    }

    if (batchName) {
      query = query.filter('batch_eq', batchName);
    }
    this.props.findAllRespondents(query);
  };

  isTranslationComplete = (survey) => !getI18nCompleteness(survey)?.errors;

  doDeliverSurvey = (respondents, isRemind = false) => {
    const available = respondents.filter((respondent) => respondent.email);
    Promise.allSettled(
      available.map((respondent) =>
        isRemind
          ? this.props.remindRespondent(
              where(
                {
                  survey_id: this.props.match.params.surveyId,
                  id: respondent.id,
                },
                { batch: this.props.survey.activeBatch }
              )
            )
          : this.props.deliverRespondent(
              where({
                survey_id: this.props.match.params.surveyId,
                id: respondent.id,
              })
            )
      )
    ).then((responses) => {
      this.handleShowSentNotifications(responses);
      this.handleRespondentsSort();
    });
    this.handleClearSelection();
  };

  _isRemind = false;
  handleRequestFilterOptions = (key, value) => {
    this.props[`surveyRespondentsAF_${key}`](
      { key, value },
      where({ survey_id: this.props.match.params.surveyId }).include(
        'grant',
        'grantee'
      )
    );
  };
  handleRespondentsFilter = (filterValues) => {
    this.props.updateQuery(
      where().filter(filterValues).paginate({ number: 1 })
    );
  };
  handleClearRespondentsFilter = (filterValues) => {
    this.props.updateQuery(
      where().filter(filterValues).actionMeta({ doDelete: true })
    );
  };
  handleDeliverToRespondents = (_respondents, isRemind = false) => {
    this._isRemind = isRemind;
    this.state.isTranslationComplete
      ? this.doDeliverSurvey([].concat(_respondents).flat(), this._isRemind)
      : this.setState({
          warningDialogOpen: true,
          respondentsToSubmit: [].concat(_respondents).flat(),
        });
  };
  handleEditContact = (respondent) => {
    this.setState({ activeRespondent: respondent, editContactOpen: true });
  };
  handleCancelEditContact = () => {
    this.setState({ activeRespondent: null, editContactOpen: false });
  };
  handleSubmitEditContact = (details) => {
    const { match } = this.props;
    this.handleCancelEditContact();
    const { id, ...rest } = details;
    const query = where({ id, survey_id: match.params.surveyId }).payload({
      ...rest,
    });
    this.props.updateRespondent(query);
  };
  handleRemindSurvey = (respondentId) => {
    this.props
      .remindRespondent(
        where(
          { survey_id: this.props.match.params.surveyId, id: respondentId },
          { batch: this.props.survey.activeBatch }
        )
      )
      .then((responses) => {
        this.hideModals();
        this.props.showNotification({
          title: 'Reminder Sent',
          message: `The reminder has been sent to the respondent`,
        });
      });
  };
  handleDeliver = () => {
    this.handleDeliverToRespondents(
      Object.values(this.state.selectedRespondents || {})
    );
    this.setState({ shareDialogOpen: false, selectedRespondents: {} });
  };
  handleToggleAll = (selectedCount) => {
    if (selectedCount === this.props.surveyRespondents.data.length) {
      return this.handleClearSelection();
    }
    const selectedRespondents = {};
    this.props.surveyRespondents.data?.forEach(
      (respondent) => (selectedRespondents[respondent.id] = respondent)
    );
    this.setState({ selectedRespondents: { ...selectedRespondents } });
  };
  handleClearSelection = () => {
    this.setState({ selectedRespondents: {} });
  };
  handleShowSentNotifications = (responses) => {
    const total = responses.length;
    const rejectedResponses = responses.filter(
      (response) => response.status === 'rejected'
    );
    const successCount = responses.length - rejectedResponses.length;

    this.props.showNotification({
      title: 'Invitation Sent',
      message: `The invitation has been sent to ${successCount} out of ${responses.length} respondents`,
    });
  };
  handleShowRemind = (respondent, isShare) => {
    const granteeSurvey = respondent.grantee_survey;
    const permalink = granteeSurvey?.permalink;
    const surveyUrl = permalink
      ? `${window.location.origin}/answer-survey/${permalink}`
      : null;
    this.setState({
      isShare,
      resendRespondent: { respondent, granteeSurvey, surveyUrl },
      reminderModalOpen: true,
    });
  };
  handleShowShare = (respondent) => {
    this.handleShowRemind(respondent, true);
  };
  handleDeleteRespondentRequest = ({ isMultiple } = {}) => {
    if (!canManageSurvey()) return;
    return confirm(
      `Delete ${isMultiple ? 'Selected ' : ''}Survey Recipient`,
      `Do you really want to delete ${
        isMultiple ? 'selected' : 'this'
      } survey recipient${
        isMultiple ? 's' : ''
      }? This action cannot be reversed and if recipient filled or started filling out a survey all related answers and taggings will be deleted as well.`,
      true
    );
  };
  handleDeleteRespondent = (respondent) => {
    if (!canManageSurvey()) return;
    this.handleDeleteRespondentRequest()
      .then(() => this.deleteRespondent(respondent))
      .catch(() => void 0);
  };
  handleDeleteSelected = () => {
    if (!canManageSurvey()) return;
    this.handleDeleteRespondentRequest({ isMultiple: true })
      .then(() => {
        const deleteRequests = [];
        Object.values(this.state.selectedRespondents || {})
          .filter(Boolean)
          .forEach(this.deleteRespondent);
        Promise.all(deleteRequests).then(this.handleClearSelection);
      })
      .catch(() => void 0);
  };
  handleCSVUpload = (file, rawCSV, csv) => {
    csv.forEach((item) => {
      this.handleCreateRespondent(item);
    });
    this.handleAddContactsPopoverCloseRequest();
  };
  handleContactsFromModal = (newContacts) => {
    newContacts.forEach((contact) => {
      let grantId;
      let granteeId;
      if (contact.owner_type === 'Grantee') {
        granteeId = contact.owner_id;
      } else if (contact.owner_type === 'Grant') {
        grantId = contact.owner_id;
      }
      this.handleCreateRespondent({
        grantee_id: granteeId,
        grant_id: grantId,
        ...contact,
      });
    });
    this.hideModals();
  };
  handleGoToDesign = () => {
    const completeness = getI18nCompleteness(this.props.survey);
    const languageToBeTranslated = Object.keys(completeness).find(
      (lang) => completeness[lang].percentage && !completeness[lang].complete
    );
    this.props.history.push(
      `/analysis/${this.props.match.params.projectId}/surveys/${this.props.match.params.surveyId}/design`,
      { activeLanguage: languageToBeTranslated }
    );
  };
  handleCreateRespondent = (data) => {
    if (Object.values(data).filter((val) => !!val).length === 0) return false;
    return this.props.createRespondent(
      where({ survey_id: this.props.match.params.surveyId }).payload({
        ...data,
        batch: this.props.survey.activeBatch,
        survey_id: this.props.match.params.surveyId,
      })
    );
  };
  handleToggledRespondent = (payload) =>
    this.setState({
      selectedRespondents: {
        ...this.state.selectedRespondents,
        [payload.id]: this.state.selectedRespondents[payload.id]
          ? null
          : payload,
      },
    });
  handlePaginationChange = ({ page, per_page }) => {
    this.props.updateQuery(where().paginate({ number: page, size: per_page }));
  };
  handleContactsPagination = ({ page, per_page }) => {
    const run =
      this.state.importDialogModel === 'Grant'
        ? this.props.grantContactsUpdateQuery
        : this.props.granteeContactsUpdateQuery;
    run.call(this, where().paginate({ number: page, size: per_page }), {
      doNotUpdateUrl: true,
    });
  };
  handleSkipWarningDialog = () => {
    this.doDeliverSurvey(this.state.respondentsToSubmit, this._isRemind);
    this.setState({ respondentsToSubmit: [], warningDialogOpen: false });
  };
  handleBatchChanged = ({ value }) => {
    this.findRespondentsByBatch(value);
    this.props.changeSurveyProperty('activeBatch', value, {
      silent: true,
      cleanSearchParam: true,
    });
    this.handleCreateNewRespondentForSerie(value);
  };
  handleCreateNewRespondentForSerie = (batch) => {
    const isNewBatch =
      batch &&
      batch.length > 0 &&
      !(this.props.survey?.batches || []).includes(batch);
    if (!isNewBatch) return;
    this.props.createRespondent(
      where({ survey_id: this.props.match.params.surveyId }).payload({
        name: '',
        email: '',
        batch,
        survey_id: this.props.match.params.surveyId,
      })
    );
    this.findRespondentsByBatch(batch);
  };
  handleAddContactsPopoverOpenRequest = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true, anchorEl: ev.currentTarget });
  };
  handleAddContactsPopoverCloseRequest = () => {
    this.setState({ popoverOpen: false });
  };
  handleRespondentsSort = (sortKey) => {
    const query = where().sort(sortKey).paginate({ number: 1 });
    this.props.updateQuery(query);
  };
  handleContactsSort = (sortKey) => {
    const run =
      this.state.importDialogModel === 'Grant'
        ? this.props.grantContactsUpdateQuery
        : this.props.granteeContactsUpdateQuery;
    run.call(this, where().sort(sortKey), { doNotUpdateUrl: true });
  };
  handleToggleFilter = (showFilterCreator) => {
    this.setState({ showFilterCreator });
  };
  deleteRespondent = (respondent) => {
    this.props.destroyRespondent(
      where({
        survey_id: this.props.match.params.surveyId,
        id: respondent.id,
      }).actionMeta({ notified: respondent.notified })
    );
  };
  showImportContactsModal = (modelType) => {
    this.setState({
      importDialogOpen: true,
      importDialogModel:
        ['Grant', 'Grantee'].indexOf(modelType) !== -1 ? modelType : null,
      popoverOpen: false,
    });
  };
  showCreateContactsModal = () => {
    this.setState({ createDialogOpen: true, popoverOpen: false });
  };
  hideModals = () => {
    if (
      !this.state.importDialogOpen &&
      !this.state.createDialogOpen &&
      !this.state.reminderModalOpen &&
      !this.state.warningDialogOpen &&
      !this.state.shareDialogOpen
    )
      return;
    this.setState({
      importDialogOpen: false,
      createDialogOpen: false,
      reminderModalOpen: false,
      warningDialogOpen: false,
      shareDialogOpen: false,
    });
  };
  renderRespondent = (respondent) => {
    const isPublic =
      respondent.grantee_survey?.public_submission || !respondent.email;
    const delivered = isPublic || respondent.notified_at;
    const anonymous = !respondent.grantee_survey_id;
    const isFinished =
      respondent.grantee_survey?.is_finished || respondent.respondent_at;
    const resendable =
      respondent.email && respondent.notified_at && !isFinished;
    const grantName = respondent.grant?.name || '–';
    const granteeName = respondent.grantee?.name || '-';
    const creationDate = respondent.created_at
      ? toLocaleDate(respondent.created_at)
      : '-';
    const respondedDate = respondent.responded_at
      ? toLocaleDateTime(respondent.responded_at)
      : '-';
    const finishedAtDate = respondent.grantee_survey?.finished_at
      ? toLocaleDateTime(respondent.grantee_survey?.finished_at)
      : '-';
    const notifiedAt =
      respondent.notified_at || respondent.grantee_survey?.notified_at
        ? toLocaleDateTime(
            respondent.notified_at || respondent.grantee_survey?.notified_at
          )
        : '-';
    const notificationDate =
      respondent.status === 'delivering' ? (
        <Icon name="wait" color="#52cccc" tip="Delivering" />
      ) : (
        notifiedAt
      );
    let icon = null;
    if (isFinished) {
      icon = (
        <Icon
          style={{ fontSize: 16, height: 16, lineHeight: 1 }}
          name="check"
          tip="finished"
          color="#52cccc"
        />
      );
    } else if (delivered) {
      icon = (
        <Icon
          style={{ fontSize: 16, height: 16, lineHeight: 1 }}
          name="send"
          tip="delivered"
          color="#52cccc"
          onClick={() => this.handleToggledRespondent(respondent)}
        />
      );
    } else if (isPublic) {
      icon = (
        <Icon
          style={{ fontSize: 16, height: 16, lineHeight: 1 }}
          name="globe"
          tip="public"
          color="#52cccc"
          onClick={() => this.handleToggledRespondent(respondent)}
        />
      );
    } else if (anonymous) {
      icon = (
        <Icon
          style={{ fontSize: 16, height: 16, lineHeight: 1 }}
          name="view-off"
          tip="anonymous"
          color="#52cccc"
          onClick={() => this.handleToggledRespondent(respondent)}
        />
      );
    }

    return (
      <Table.Row key={respondent.id}>
        <Table.Cell>
          <Checkbox
            noLabel
            checked={!!this.state.selectedRespondents[respondent.id]}
            onCheck={() => this.handleToggledRespondent(respondent)}
          />
        </Table.Cell>
        <Table.Cell>{icon}</Table.Cell>
        <Table.Cell text={respondent.name || '–'} />
        <Table.Cell
          text={
            respondent.email?.length > 0
              ? respondent.email
              : 'Public submission'
          }
        />
        {isGrantless(this.props.project) ? null : (
          <Table.Cell text={grantName} />
        )}
        {isGrantless(this.props.project) ? null : (
          <Table.Cell text={granteeName} />
        )}
        <Table.Cell textAlign="right" text={creationDate} />
        <Table.Cell textAlign="right" text={notificationDate} />
        <Table.Cell textAlign="right" text={respondedDate} />
        <Table.Cell textAlign="right" text={finishedAtDate} />
        <Table.Cell actions icons style={{ textAlign: 'right' }}>
          {!delivered && (
            <Icon
              name="send"
              tip="Send Survey Notification"
              onClick={() => this.handleDeliverToRespondents(respondent)}
            />
          )}
          {resendable && (
            <Icon
              name="rotate"
              tip="Send Remind to respond"
              onClick={() => this.handleShowRemind(respondent)}
            />
          )}
          <Icon
            name="share"
            disabled={anonymous || isFinished || !respondent?.grantee_survey}
            tip={
              anonymous || isFinished
                ? 'Permalink access not available for anonymous and finished surveys'
                : ''
            }
            onClick={() => this.handleShowShare(respondent)}
          />
          <Icon
            name="account"
            onClick={() => this.handleEditContact(respondent)}
          />
          {canManageSurvey() && (
            <Icon
              name="trash"
              tip="Remove"
              onClick={() => this.handleDeleteRespondent(respondent)}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderRespondents(selectedCount) {
    const { surveyRespondents } = this.props;
    if (surveyRespondents.pending.init) {
      return <Progress />;
    }
    if (!surveyRespondents.data.length) {
      return (
        <Section>
          <Container centerHorizontal horizontal>
            <div>
              <img
                src={noResponses}
                alt="noResponses"
                style={{ width: 300, height: 266 }}
              />
            </div>
          </Container>
          <CardEmpty
            large
            title="There are no respondents"
            description={`Add them manually or by uploading a CSV file. ${
              this.state.filterQuery
                ? `Queried for: ${this.state.filterQuery}`
                : ''
            }`}
          />
        </Section>
      );
    }
    return (
      <div>
        <TableComposition compact>
          {surveyRespondents.pending.ui && <Progress className="absolute" />}
          <Table.Head
            jsonapi
            sortBy={surveyRespondents.meta.getAction?.queryParams.sort}
            onSortChange={this.handleRespondentsSort}
          >
            <Table.HCell textAlign="center" width={20}>
              <Checkbox
                noLabel
                checked={
                  selectedCount > 0 &&
                  selectedCount === surveyRespondents.data.length
                }
                onCheck={() => this.handleToggleAll(selectedCount)}
              />
            </Table.HCell>
            <Table.HCell textAlign="center" width={20} sortBy="status" />
            <Table.HCell text="Name" sortBy="name" />
            <Table.HCell text="Email" sortBy="email" />
            {isGrantless(this.props.project) ? null : (
              <Table.HCell text={getText('Grant')} sortBy="grant_name" />
            )}
            {isGrantless(this.props.project) ? null : (
              <Table.HCell text={getText('Grantee')} sortBy="grantee_name" />
            )}
            <Table.HCell
              textAlign="center"
              width={120}
              text="Added"
              sortBy="created_at"
            />
            <Table.HCell
              textAlign="center"
              width={130}
              text="Notified"
              sortBy="notified_at"
            />
            <Table.HCell
              textAlign="center"
              width={130}
              text="Responded"
              sortBy="responded_at"
            />
            <Table.HCell
              textAlign="center"
              width={130}
              text="Finished"
              sortBy="grantee_survey_finished_at"
            />
            <Table.HCell width={100} />
          </Table.Head>
          <Table.Body>
            {surveyRespondents.data.map(this.renderRespondent)}
          </Table.Body>
        </TableComposition>

        <CardFooter>
          <PaginationIm
            {...(surveyRespondents.links.meta || {})}
            label="respondents"
            items_label="respondents"
            onChange={this.handlePaginationChange}
          />
        </CardFooter>
      </div>
    );
  }

  get shareStats() {
    const completedCountAll = this.getEveryone().filter((a) => {
      if (a.status == 'finished') return true;
      if (a.grantee_survey?.is_finished) return true;
      return false;
    }).length;
    const notFinishedCountAll = this.getEveryone()
      .filter((a) => a?.email)
      .filter((a) => {
        if (a.status == 'finished') return false;
        if (a.grantee_survey?.is_finished) return false;
        return true;
      }).length;
    const unNotifiedCountAll = this.getEveryone()
      .filter((a) => a?.email)
      .filter((a) => {
        if (a.status == 'finished') return false;
        if (a.grantee_survey?.is_finished) return false;
        return true;
      })
      .filter((a) => !a.notified_at).length;
    const notStartedCountAll = this.getEveryone()
      .filter((a) => a?.email)
      .filter((a) => {
        if (a.status == 'finished') return false;
        if (a.grantee_survey?.is_finished) return false;
        return true;
      })
      .filter((a) => !a.responded_at).length;
    const inProgressCountAll = this.getEveryone()
      .filter((a) => a?.email)
      .filter((a) => {
        if (a.status == 'finished') return false;
        if (a.grantee_survey?.is_finished) return false;
        return true;
      })
      .filter((a) => a.responded_at).length;
    return [
      {
        title: 'Completed',
        value: completedCountAll,
        descr: 'fully completed',
        onClick: () => {
          this.handleRespondentsFilter([
            { key: 'status_eq', value: 'finished' },
            { key: 'grantee_survey_is_finished_eq', value: 'true' },
          ]);
        },
      },
      {
        title: 'Not Notified',
        value: unNotifiedCountAll,
        descr: 'not notified yet',
        onClick: () => {
          this.handleRespondentsFilter([
            { key: 'status_not_eq', value: 'finished' },
            { key: 'grantee_survey_is_finished_eq', value: 'false' },
            { key: 'grantee_survey_is_notified_eq', value: 'false' },
          ]);
        },
      },
      {
        title: 'Not Started',
        value: notStartedCountAll,
        descr: 'not started yet',
        onClick: () => {
          this.handleRespondentsFilter([
            { key: 'status_not_eq', value: 'finished' },
            { key: 'grantee_survey_is_finished_eq', value: 'false' },
            { key: 'responded_at_null', value: 'true' },
          ]);
        },
      },
      {
        title: 'In Progress',
        value: inProgressCountAll,
        descr: 'already started',
        onClick: () => {
          this.handleRespondentsFilter([
            { key: 'status_not_eq', value: 'finished' },
            { key: 'grantee_survey_is_finished_eq', value: 'false' },
            { key: 'responded_at_null', value: 'false' },
          ]);
        },
      },
      {
        title: 'Not Finished',
        value: notFinishedCountAll,
        descr: 'not finished yet',
        onClick: () => {
          this.handleRespondentsFilter([
            { key: 'status_not_eq', value: 'finished' },
            { key: 'grantee_survey_is_finished_eq', value: 'false' },
          ]);
        },
      },
    ];
  }

  getEveryone = () => (this.props.surveyRespondents.data || []).filter(Boolean);
  getSelected = () =>
    Object.values(this.state.selectedRespondents || {}).filter(Boolean);
  notFinished = (subset) =>
    (subset ? this.getSelected() : this.getEveryone())
      .filter((a) => a?.email)
      .filter((a) => {
        if (a.status == 'finished') return false;
        if (a.grantee_survey?.is_finished) return false;
        return true;
      });
  notNotified = (subset) =>
    this.notFinished(subset).filter((a) => !a.notified_at);
  notStarted = (subset) =>
    this.notFinished(subset).filter((a) => !a.responded_at);
  inProgress = (subset) =>
    this.notFinished(subset).filter((a) => a.responded_at);

  render() {
    const { survey, surveyRespondents, grantContacts, granteeContacts, match } =
      this.props;
    const {
      importDialogOpen,
      importDialogModel,
      createDialogOpen,
      shareDialogOpen,
      reminderModalOpen,
      resendRespondent,
      warningDialogOpen,
      isTranslationComplete,
      showFilterCreator,
    } = this.state;
    const selectedCount = Object.keys(this.state.selectedRespondents).length;
    const hasSelects = selectedCount > 0;
    const deliverAll =
      hasSelects && selectedCount >= this.notFinished(false).length;

    return (
      <div>
        <Section collapsed>
          <Warning
            compact
            id={survey.id}
            type="surveyTranslations"
            show={!isTranslationComplete}
            text="Your survey is not fully translated."
          />
          <Warning
            compact
            id={survey.id}
            type="surveyDeadline"
            show={
              !!survey.id &&
              !!survey.deadline &&
              isBefore(survey.deadline, Date.now(), true)
            }
            text="Your survey end date has passed."
          />

          <SettingsWrapper>
            <SettingDeadline
              projectId={match.params.projectId}
              survey={survey}
            />
            <SettingAnonymous
              projectId={match.params.projectId}
              survey={survey}
            />
            <SettingBatch
              active={survey.activeBatch}
              series={survey.batches}
              onSeriesChange={this.handleBatchChanged}
            />
            <SettingPublic projectId={match.params.projectId} survey={survey} />
          </SettingsWrapper>

          <Stats small data={this.shareStats} />

          <br />
          <Section.Header
            title={`Contacts ${
              survey.activeBatch ? ` - ${survey.activeBatch}` : ''
            }`}
            leftActions={
              <FilterTrigger
                active={showFilterCreator}
                filters={surveyRespondents.meta.getAction?.queryParams.filter}
                config={this.filterConfig}
                onToggle={() => this.handleToggleFilter(!showFilterCreator)}
              />
            }
            rightActions={
              <Button
                secondary
                label="Add contacts"
                size="m"
                onClick={this.handleAddContactsPopoverOpenRequest}
              />
            }
          />
          <br />
          <FilterJsonapi
            showCreator={showFilterCreator}
            filters={surveyRespondents.meta.getAction?.queryParams.filter}
            config={this.filterConfig}
            configAutopopulation={this.filterAutopopulationConfig}
            onToggleCreator={this.handleToggleFilter}
            onClear={this.handleClearRespondentsFilter}
            onSubmit={this.handleRespondentsFilter}
            renderSelected={() => (
              <Divider className={cls.filterSelectedDivider} />
            )}
          />
          <Card normalPadding>{this.renderRespondents(selectedCount)}</Card>
          <br />
          <br />
          <br />
          {/* handle footer padding */}
        </Section>

        <Section
          type="sticky-footer"
          className={cls.chartFooter}
          secondary={hasSelects}
        >
          <Container horizontal>
            <Actions>
              {hasSelects && (
                <Button
                  negative
                  size="l"
                  label="Discard"
                  onClick={this.handleClearSelection}
                />
              )}
              {hasSelects && (
                <React.Fragment>
                  {' '}
                  <Button
                    alert
                    size="l"
                    label={`Delete (${selectedCount} selected)`}
                    onClick={this.handleDeleteSelected}
                  />
                </React.Fragment>
              )}
            </Actions>
            <Actions>
              {this.notNotified(hasSelects).length > 0 && (
                <Button
                  negative={hasSelects}
                  size="l"
                  label={`Send not Notified (${
                    this.notNotified(hasSelects).length
                  })`}
                  onClick={() =>
                    this.handleDeliverToRespondents(
                      this.notNotified(hasSelects),
                      false
                    )
                  }
                />
              )}
              {this.notStarted(hasSelects).length > 0 && (
                <Button
                  negative={hasSelects}
                  size="l"
                  label={`Remind not started (${
                    this.notStarted(hasSelects).length
                  })`}
                  onClick={() =>
                    this.doDeliverSurvey(this.notStarted(hasSelects), true)
                  }
                />
              )}
              {this.inProgress(hasSelects).length > 0 && (
                <Button
                  negative={hasSelects}
                  size="l"
                  label={`Remind in progress (${
                    this.inProgress(hasSelects).length
                  })`}
                  onClick={() =>
                    this.doDeliverSurvey(this.inProgress(hasSelects), true)
                  }
                />
              )}
              {this.notFinished(hasSelects).length > 0 && (
                <Button
                  negative={hasSelects}
                  size="l"
                  label={`Remind Unfinished (${
                    this.notFinished(hasSelects).length
                  })`}
                  onClick={() =>
                    this.doDeliverSurvey(this.notFinished(hasSelects), true)
                  }
                />
              )}
            </Actions>
          </Container>
        </Section>

        <ImportContactsModal
          open={importDialogOpen}
          model={importDialogModel}
          contacts={
            importDialogModel === 'Grant' ? grantContacts : granteeContacts
          }
          respondents={surveyRespondents.data}
          onCancel={this.hideModals}
          onSubmit={this.handleContactsFromModal}
          onSort={this.handleContactsSort}
          onPaginate={this.handleContactsPagination}
        />
        <CreateContactsModal
          open={createDialogOpen}
          respondents={surveyRespondents.data}
          onCancel={this.hideModals}
          onSubmit={this.handleContactsFromModal}
        />
        <EditContactModal
          key={(this.state.activeRespondent || {}).id}
          open={this.state.editContactOpen}
          src={this.state.activeRespondent || {}}
          batches={survey.batches}
          public={survey.public}
          onCancel={this.handleCancelEditContact}
          onSubmit={this.handleSubmitEditContact}
        />
        <SurveyReminderModal
          open={reminderModalOpen}
          isShare={this.state.isShare}
          respondentInfo={resendRespondent}
          onCancel={this.hideModals}
          onSubmit={this.handleRemindSurvey}
        />
        <ShareConfirmationModal
          open={shareDialogOpen}
          series={survey.activeBatch}
          deliverAll={deliverAll}
          deliverSelected={!deliverAll}
          onCancel={this.hideModals}
          onSubmit={this.handleDeliver}
        />
        <WarningDialog
          open={warningDialogOpen}
          completeness={getI18nCompleteness(survey)}
          onCancel={this.handleSkipWarningDialog}
          onGoToDesign={this.handleGoToDesign}
        />
        <PopoverLight
          anchorEl={this.state.anchorEl}
          open={this.state.popoverOpen}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          className={cls.contactsPopover}
          onRequestClose={this.handleAddContactsPopoverCloseRequest}
          header={<span>Add contacts</span>}
        >
          <PopoverLight.Menu>
            {isGrantless(this.props.project) ? null : (
              <PopoverLight.MenuItem
                leftIcon={<Icon name="grantee" />}
                primaryText={getText('Grantees')}
                onClick={() => this.showImportContactsModal('Grantee')}
              />
            )}
            {isGrantless(this.props.project) ? null : (
              <PopoverLight.MenuItem
                leftIcon={<Icon name="grants" />}
                primaryText={getText('Grants')}
                onClick={() => this.showImportContactsModal('Grant')}
              />
            )}
            <PopoverLight.MenuItem
              leftIcon={<Icon name="add-element" />}
              primaryText="Add manually"
              onClick={this.showCreateContactsModal}
            />
            <PopoverLight.MenuItem
              leftIcon={<Icon name="table" />}
              primaryText={
                <ContactCSVUploadButton
                  text
                  handleUpload={this.handleCSVUpload}
                />
              }
              onClick={this.showCreateContactsModal}
            />
          </PopoverLight.Menu>
        </PopoverLight>
      </div>
    );
  }
}
