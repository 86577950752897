import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import granteeSurveysApi from 'src/api/GranteeSurveys';
import { surveyExportApi } from 'src/api/SurveyExport';
import surveyRespondentsApi from 'src/api/SurveyRespondents';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import ExportConfiguration from 'src/components/ExportConfiguration/ExportConfiguration';
import ExportConfirmation from 'src/components/ExportConfirmation/ExportConfirmation';
import {
  Status,
  Card,
  CardEmpty,
  Container,
  CardFooter,
  Section,
  Table,
  Tag,
  Progress,
  Button,
  FilterJsonapi,
  FilterTrigger,
  PopoverLight,
} from 'src/components/IMUI';
import cls from './Responses.module.css';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import Stats from 'src/components/Stats/Stats';
import history from 'src/historyInstance';
import { getText } from 'src/services/DictionaryService';
import { isGrantless } from 'src/utils/analysis';
import { dedummy, dummyGrant, dummyGrantee } from 'src/utils/string';
import { where } from 'im/api/Query';
import { numberMatchers } from 'src/data/matchers';
import { Icon } from 'im/ui/Icon';
import EditContactModal from '../components/EditContactModal';
import { canManageSurvey, surveyOnly } from 'src/userStorage';
import { toLocaleDateTime } from 'src/utils/date';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import { DEFAULT_SERIES } from '../Share/components/SettingBatch';
import noResponses from 'src/static/waiting.png';

@connect(pick(['survey', 'project', 'granteeSurveys']), {
  findAllGranteeSurveys: granteeSurveysApi.findAll,
  findAllGranteeSurveysUpdate: granteeSurveysApi.findAll.updateQuery,
  destroyGranteeSurveys: granteeSurveysApi.destroy,
  updateRespondent: surveyRespondentsApi.put,
  exportSurvey: surveyExportApi.single,
})
export default class SurveyResponses extends React.PureComponent {
  state = {
    popoverAnchorEl: null,
    exportPopoverOpen: false,
    exportConfigurationShow: false,
    exportModalShow: false,
    activeResponse: null,
  };
  static propTypes = {
    ...ReactRouterPropTypes,
    survey: PropTypes.object,
    granteeSurveys: PropTypes.object,
    project: PropTypes.object,
    findAllGranteeSurveys: PropTypes.func,
    destroyGranteeSurveys: PropTypes.func,
    updateRespondent: PropTypes.func,
    activeStepId: PropTypes.number,
  };
  componentDidMount() {
    this.doRequestData();
  }
  doRequestData = ({ page = 1, per_page = 30 } = {}) => {
    const {
      match: { params },
      location: { search },
    } = this.props;
    this.props.findAllGranteeSurveys(
      where()
        .paginate({ number: page, size: per_page })
        .fromString(search)
        .filter('survey_id_eq', params.surveyId)
        .sort(
          '-is_finished',
          '-finished_at',
          '-is_notified',
          '-notified_at',
          '-created_at'
        )
    );
  };
  goToSurveyReview = (granteeSurveyId) => {
    history.push(
      `/analysis/${this.props.match.params.projectId}/surveys/${this.props.match.params.surveyId}/responses/${granteeSurveyId}`
    );
  };
  handlePaginationChange = ({ page, per_page }) => {
    this.props.findAllGranteeSurveysUpdate(
      where()
        .filter('survey_id_eq', this.props.match.params.surveyId)
        .paginate({ number: page, size: per_page })
    );
  };

  handleExportPopoverOpen = (ev) => {
    this.setState({
      exportPopoverOpen: true,
      popoverAnchorEl: ev.currentTarget,
    });
  };

  handleExportPopoverClose = (ev) => {
    this.setState({
      exportPopoverOpen: false,
      popoverAnchorEl: null,
    });
  };

  handleExportConfiguration = () => {
    this.setState({
      exportConfigurationShow: true,
      exportPopoverOpen: false,
      popoverAnchorEl: null,
    });
  };

  handleExportSurvey = (email = null) => {
    this.props
      .exportSurvey(
        where(
          {
            project_id: this.props.match.params.projectId,
            id: this.props.match.params.surveyId,
          },
          { email: email }
        ).fromString(this.props.location.search)
      )
      .then(() =>
        this.setState({
          exportConfigurationShow: false,
          exportModalShow: true,
          exportPopoverOpen: false,
          popoverAnchorEl: null,
        })
      );
  };
  handleCloseExportConfigurationModal = () => {
    this.setState({ exportConfigurationShow: false });
  };
  handleCloseExportModal = () => {
    this.setState({ exportModalShow: false });
  };
  handleGranteeSurveyDelete = (granteeSurveyId) => {
    if (!canManageSurvey()) return;
    confirm(
      'Delete Survey Response',
      'Do you really want to delete this survey response? This action cannot be reversed and all related answers and taggings will be deleted as well.',
      true
    ).then(
      () => {
        this.props.destroyGranteeSurveys(where({ id: +granteeSurveyId }));
      },
      () => void 0
    );
  };
  handleResponsesSort = (sortKey) => {
    this.props.findAllGranteeSurveysUpdate(where().sort(sortKey));
  };
  handleResponsesFilter = (filterValues) => {
    this.props.findAllGranteeSurveysUpdate(
      where().filter(filterValues).paginate({ number: 1 })
    );
  };
  handleClearResponsesFilter = (filterValues) => {
    this.props.findAllGranteeSurveysUpdate(
      where()
        .filter(filterValues)
        .paginate({ number: 1 })
        .actionMeta({ doDelete: true })
    );
  };
  handleToggleResponsesFilter = (showFilters) => {
    this.setState({
      showFilters:
        typeof showFilters === 'boolean'
          ? showFilters
          : !this.state.showFilters,
    });
  };
  handleEditContact = (ev, response) => {
    ev.stopPropagation();
    this.setState({ activeResponse: response, editContactOpen: true });
  };
  handleCancelEditContact = () => {
    this.setState({ activeResponse: null, editContactOpen: false });
  };
  handleSubmitEditContact = async ({
    name,
    email,
    batch,
    grant_id: grantId,
    grantee_id: granteeId,
  } = {}) => {
    this.handleCancelEditContact();
    await this.props.updateRespondent(
      where({
        id: this.state.activeResponse.respondent.id,
        survey_id: this.props.match.params.surveyId,
      })
        .payload({
          name,
          email,
          batch,
          grant_id: grantId,
          grantee_id: granteeId,
        })
        .actionMeta({ noSync: true })
    );
    this.doRequestData({
      page: this.props.granteeSurveys.links.meta.number,
      per_page: this.props.granteeSurveys.links.meta.size,
    });
  };
  get filterConfig() {
    return [
      {
        name: 'id',
        displayName: 'Submission ID',
        matchers: numberMatchers,
        type: 'number',
      },
      { name: 'respondent_name', displayName: 'Respondent Name', type: 'text' },
      { name: 'respondent_email', displayName: 'Email', type: 'text' },
      surveyOnly()
        ? null
        : {
            name: 'grantee_name',
            displayName: getText('Grantee Name'),
            type: 'text',
          },
      surveyOnly()
        ? null
        : {
            name: 'grant_name',
            displayName: getText('Grant Name'),
            type: 'text',
          },
      { name: 'language', displayName: 'Language', type: 'text' },
      { name: 'batch', displayName: 'Series', type: 'text' },
      { name: 'notified_at', displayName: 'Notification Date', type: 'date' },
      {
        name: 'respondent_responded_at',
        displayName: 'Responded At',
        type: 'date',
      },
      { name: 'finished_at', displayName: 'Submission Date', type: 'date' },
      {
        name: 'is_notified',
        displayName: 'Is notified?',
        type: 'select',
        matchers: ['eq'],
        values: ['true', 'false'],
      },
      {
        name: 'is_finished',
        displayName: 'Is finished?',
        type: 'select',
        matchers: ['eq'],
        values: ['true', 'false'],
      },
    ].filter(Boolean);
  }

  get granteeSurveysStats() {
    const isNumeric = (v) => !isNaN(Number(v)) && isFinite(Number(v));
    const rawRate =
      this.props.survey.finished_surveys_count /
      this.props.survey.respondents_count;
    const submissionRate = isNumeric(rawRate)
      ? Math.round(100 * rawRate * 10) / 10
      : 0;
    const statusProps = {};
    if (submissionRate >= 75) {
      statusProps.done = true;
    } else if (submissionRate >= 25) {
      statusProps.updated = true;
    } else {
      statusProps.alarming = true;
    }
    return [
      {
        title: 'Respondents',
        value: this.props.survey.respondents_count,
        descr: 'shared with',
      },
      {
        title: 'In Progress',
        value: ' ',
        descr: 'already started',
        onClick: () => {
          this.handleResponsesFilter([
            { key: 'is_finished_eq', value: 'false' },
            { key: 'respondent_responded_at_null', value: 'false' },
          ]);
        },
      },
      {
        title: 'Completed',
        value: this.props.survey.finished_surveys_count,
        descr: 'fully completed',
        onClick: () => {
          this.handleResponsesFilter([
            { key: 'is_finished_eq', value: 'true' },
          ]);
        },
      },
      {
        title: 'Not completed',
        value:
          this.props.unfinished_surveys_count ??
          this.props.survey.respondents_count -
            this.props.survey.finished_surveys_count,
        descr: 'not submitted yet',
        onClick: () => {
          this.handleResponsesFilter([
            { key: 'is_finished_eq', value: 'false' },
          ]);
        },
      },
      {
        title: 'Submission rate',
        value: `${submissionRate}%`,
        insight: <Status {...statusProps} />,
      },
    ];
  }

  navigateSurveyUrl = (ev, url) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    window.open(url, '_blank');
  };
  renderResponseRow = (gs) => {
    const anonymous =
      ((gs.grantee_name === dummyGrantee && gs.grant_name === dummyGrant) ||
        gs.anonymous) &&
      !gs.respondent_email &&
      !gs.respondent_name;
    const respondentMail = anonymous
      ? `Submission ID ${gs.id}`
      : gs.respondent_email;
    const notifiedDate =
      gs.is_notified && gs.notified_at ? gs.notified_at : null;
    const finishedDate = gs.is_finished
      ? gs.finished_at || gs.notified_at
      : null;
    const currentUrl =
      !gs?.is_finished && !gs.finished_at && gs?.permalink
        ? `/answer-survey/${gs.permalink}`
        : null;
    const respondentText = gs.respondent_name || respondentMail || '\u200B';
    const respondentLabel =
      gs.respondent_name?.length > 0 ? respondentMail || '-' : '\u200B';

    let renderIcon = null;
    if (finishedDate) {
      renderIcon = (
        <Icon
          style={{
            fontSize: 16,
            margin: 2,
            height: 18,
            display: 'block',
            lineHeight: 1,
          }}
          name="check"
          tip="finished"
          color="#52cccc"
        />
      );
    } else if (notifiedDate) {
      renderIcon = (
        <Icon
          style={{
            fontSize: 16,
            margin: 2,
            height: 18,
            display: 'block',
            lineHeight: 1,
          }}
          name="send"
          tip="notified"
          color="#52cccc"
        />
      );
    } else if (gs.public_submission) {
      renderIcon = (
        <Icon
          style={{
            fontSize: 16,
            margin: 2,
            height: 18,
            display: 'block',
            lineHeight: 1,
          }}
          name="globe"
          tip="public"
          color="#52cccc"
        />
      );
    } else if (anonymous) {
      renderIcon = (
        <Icon
          style={{
            fontSize: 16,
            margin: 2,
            height: 18,
            display: 'block',
            lineHeight: 1,
          }}
          name="view-off"
          tip="anonymous"
          color="#52cccc"
        />
      );
    }

    return (
      <Table.Row key={gs.id} onClick={() => this.goToSurveyReview(gs.id)}>
        <Table.Cell
          to={`/analysis/${this.props.match.params.projectId}/surveys/${this.props.match.params.surveyId}/responses/${gs.id}`}
          text={renderIcon}
        />
        <Table.Cell
          to={`/analysis/${this.props.match.params.projectId}/surveys/${this.props.match.params.surveyId}/responses/${gs.id}`}
          text={respondentText}
          label={respondentLabel}
        />
        <Table.Cell
          to={`/analysis/${this.props.match.params.projectId}/surveys/${this.props.match.params.surveyId}/responses/${gs.id}`}
          text={
            gs.respondent_email?.length > 0
              ? gs.respondent_email
              : 'Public submission'
          }
        />
        {this.hasGrantees() && (
          <Table.Cell>{dedummy(gs.grantee_name, '–')}</Table.Cell>
        )}
        {this.hasGrants() && (
          <Table.Cell>{dedummy(gs.grant_name, '–')}</Table.Cell>
        )}
        {this.hasMultLanguage() && (
          <Table.Cell textAlign="center">
            <Tag
              outline
              label={gs.language || this.props.survey.languages[0]}
            />
          </Table.Cell>
        )}
        {this.hasCustomSeries() && (
          <Table.Cell textAlign="center" text={gs.batch || '–'} />
        )}
        <Table.Cell textAlign="right">
          {notifiedDate ? toLocaleDateTime(notifiedDate) : '–'}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {gs.respondent_responded_at
            ? toLocaleDateTime(gs.respondent_responded_at)
            : '–'}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {finishedDate ? toLocaleDateTime(finishedDate) : '–'}
        </Table.Cell>
        <Table.Cell actions icons textAlign="right">
          {currentUrl && (
            <Icon
              name="globe"
              tip="Permalink access to unfinished surveys"
              onClick={(ev) => this.navigateSurveyUrl(ev, currentUrl)}
            />
          )}
          <Icon
            name="account"
            tip="Edit contact details"
            onClick={(ev) => this.handleEditContact(ev, gs)}
          />
          <Icon
            name="view"
            color={'#272e42'}
            onClick={() => this.goToSurveyReview(gs.id)}
          />
          {canManageSurvey() && (
            <Icon
              name="trash"
              tip="Remove"
              color={'#272e42'}
              onClick={(ev) => {
                ev.stopPropagation();
                this.handleGranteeSurveyDelete(gs.id);
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  hasGrants() {
    return (
      !isGrantless(this.props.project) &&
      this.props.granteeSurveys.data?.filter((gs) =>
        dedummy(gs.grant_name, null)
      )?.length > 0
    );
  }
  hasGrantees() {
    return (
      !isGrantless(this.props.project) &&
      this.props.granteeSurveys.data?.filter((gs) =>
        dedummy(gs.grantee_name, null)
      )?.length > 0
    );
  }
  hasCustomSeries() {
    return (
      this.props.survey.batches?.filter((b) => b != DEFAULT_SERIES).length > 0
    );
  }
  hasMultLanguage() {
    return this.props.survey.languages?.length > 1;
  }

  renderTable() {
    return (
      <Container>
        <TableComposition compact>
          <Table.Head
            jsonapi
            onSortChange={this.handleResponsesSort}
            sortBy={this.props.granteeSurveys.meta.getAction?.queryParams.sort}
          >
            <Table.HCell width={40} sortBy="is_finished" />
            <Table.HCell text="Respondent" sortBy="respondent_name" />
            <Table.HCell text="Email" width={250} sortBy="respondent_email" />
            {this.hasGrantees() && (
              <Table.HCell text={getText('Grantee')} sortBy="grantee_name" />
            )}
            {this.hasGrants() && (
              <Table.HCell text={getText('Grant')} sortBy="grant_name" />
            )}
            {this.hasMultLanguage() && (
              <Table.HCell textAlign="center" width={46} sortBy="language" />
            )}
            {this.hasCustomSeries() && (
              <Table.HCell
                textAlign="center"
                width={92}
                text="Series"
                sortBy="batch"
              />
            )}
            <Table.HCell
              textAlign="center"
              width={130}
              text="Notified"
              sortBy="notified_at"
            />
            <Table.HCell
              textAlign="center"
              width={130}
              text="Responded"
              sortBy="respondent_responded_at"
            />
            <Table.HCell
              textAlign="center"
              width={130}
              text="Finished"
              sortBy="finished_at"
            />
            <Table.HCell textAlign="center" width={120} text="" />
          </Table.Head>
          <Table.Body>
            {this.props.granteeSurveys.data.map(this.renderResponseRow)}
          </Table.Body>
        </TableComposition>
        <CardFooter>
          <PaginationIm
            label="responses"
            items_label="responses"
            onChange={this.handlePaginationChange}
            {...(this.props.granteeSurveys.links.meta || {})}
          />
        </CardFooter>
      </Container>
    );
  }

  renderEmpty() {
    return (
      <Section>
        <Container centerHorizontal horizontal>
          <div>
            <img
              src={noResponses}
              alt="noResponses"
              style={{ width: 400, height: 400 }}
            />
          </div>
        </Container>
        <CardEmpty
          large
          title="There are no responses yet"
          description="Awaiting valuable insights from our users."
        />
      </Section>
    );
  }

  render() {
    const { granteeSurveys, survey } = this.props;
    const {
      exportConfigurationShow,
      exportModalShow,
      exportPopoverOpen,
      popoverAnchorEl,
      showFilters,
    } = this.state;
    if (granteeSurveys.pending.init || survey.pending) {
      return (
        <Section>
          <Card>
            <Progress />
          </Card>
        </Section>
      );
    }
    const { queryParams = {} } = granteeSurveys.meta.getAction || {};
    return (
      <div>
        <Section collapsed>
          {!!granteeSurveys.data.length && (
            <Stats small data={this.granteeSurveysStats} />
          )}
        </Section>
        <Section collapsed>
          <FilterJsonapi
            showCreator={showFilters}
            filters={queryParams.filter}
            config={this.filterConfig}
            onToggleCreator={this.handleToggleResponsesFilter}
            onClear={this.handleClearResponsesFilter}
            onSubmit={this.handleResponsesFilter}
          />
          <Section.Header
            leftActions={
              <FilterTrigger
                active={showFilters}
                filters={queryParams.filter}
                config={this.filterConfig}
                onToggle={this.handleToggleResponsesFilter}
              />
            }
            rightActions={
              <Button
                secondary
                label="Export"
                onClick={this.handleExportPopoverOpen}
              />
            }
          />
        </Section>
        <Section>
          {this.props.granteeSurveys.pending.ui &&
            granteeSurveys.data.length > 0 && (
              <div className="progressOverlay">
                <Progress className="absolute" />
              </div>
            )}
          {!this.hasGrants() &&
            !this.hasGrantees() &&
            granteeSurveys.data.length != 0 && (
              <Container>
                <div className={cls.respondentTip}>
                  <Icon name="question-1" />
                  TIP: You may associate grants and granteees to each respondent
                  by editing their <Icon name="account" /> contact details
                </div>
              </Container>
            )}
          <Card normalPadding>
            {granteeSurveys.data.length == 0
              ? this.renderEmpty()
              : this.renderTable()}
          </Card>
        </Section>
        <EditContactModal
          isResponse
          key={this.state.activeResponse?.id}
          batches={survey.batches}
          public={survey.public}
          open={this.state.editContactOpen}
          src={this.state.activeResponse || {}}
          onCancel={this.handleCancelEditContact}
          onSubmit={this.handleSubmitEditContact}
        />
        <PopoverLight
          anchorEl={popoverAnchorEl}
          open={exportPopoverOpen}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleExportPopoverClose}
          header={'Export'}
        >
          <PopoverLight.Menu>
            <PopoverLight.MenuItem
              leftIcon={<Icon name="email" />}
              primaryText="Export to my email"
              onClick={() => this.handleExportSurvey()}
            />
            <PopoverLight.MenuItem
              leftIcon={<Icon name="share" />}
              primaryText="Share via email"
              onClick={this.handleExportConfiguration}
            />
          </PopoverLight.Menu>
        </PopoverLight>
        <ExportConfiguration
          open={exportConfigurationShow}
          onSubmit={this.handleExportSurvey}
          onRequestClose={this.handleCloseExportConfigurationModal}
        />
        <ExportConfirmation
          open={exportModalShow}
          onRequestClose={this.handleCloseExportModal}
        />
      </div>
    );
  }
}
