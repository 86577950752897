import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/v3/survey_questions',
  type: 'survey_question',
  api: {
    allow: ['put'],
    custom: {
      translate: {
        path: ':id/translate',
        method: 'PUT',
      },
    },
  },
};

const SurveyQuestion = new Resource(apiConfig, 'SurveyQuestion');
reducerRegistry.register(SurveyQuestion);

export default SurveyQuestion.api;
