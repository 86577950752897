import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const getGroupForTag = (tag) => tag?.tag_categories?.[0] ?? {};

const apiConfig = {
  root: '/v3/surveys',
  type: 'survey',
  api: {
    allow: ['find'],
    custom: {
      translate: {
        path: ':id/translate',
        method: 'PUT',
      },
    },
  },
  derived: () => ({ getGroupForTag }),
};

const Survey = new Resource(apiConfig, 'surveyJsonapi');
reducerRegistry.register(Survey);

export default Survey.api;
