import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import pick from 'ramda/src/pick';
import {
  Actions,
  Button,
  Container,
  Section,
  SelectPopoverLight,
} from 'src/components/IMUI';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import LanguageSelector from 'src/components/LanguageSelector/LanguageSelector';
import RenameSurveyModal from './RenameSurveyModal';
import { canManageSurvey } from 'src/userStorage';
import surveyApi from 'src/api/Survey';
import { where } from 'im/api/Query';
import { getSurvey } from 'src/actionCreators/surveyActionCreators';

const TRANSLATION_MAX_ATTEMPTS = 20;
const TRANSLATION_POLLING_INTERVAL = 5000;

@connect(pick(['survey']), {
  translateSurvey: surveyApi.translate,
  getSurvey,
  showNotification,
})
export default class SurveyEditFooter extends Component {
  static propTypes = {
    survey: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
    completeness: PropTypes.object,
    onLanguageChanged: PropTypes.func,
    onSaveSurvey: PropTypes.func,
    translationProgress: PropTypes.number,
    translateSurvey: PropTypes.func,
    getSurvey: PropTypes.func,
    onRenameSurvey: PropTypes.func,
    onJumpToQuestion: PropTypes.func,
    renderRightAction: PropTypes.func,
    showNotification: PropTypes.func,
    hasAutoTranslateButton: PropTypes.bool,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  state = {
    showRenameSurveyModal: false,
    saveAfterRename: false,
    translationIntervalId: null,
    translationPollAttemptCount: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { survey } = this.props;
    const { saveAfterRename } = this.state;
    if (saveAfterRename && survey.name !== nextProps.survey.name) {
      this.props.onSaveSurvey();
      this.setState({ saveAfterRename: false });
    }
  }

  onRenameSurveyModalClose = () => {
    this.setState({
      showRenameSurveyModal: false,
      saveAfterRename: false,
    });
  };

  onRenameSurvey = (name) => {
    this.setState({ showRenameSurveyModal: false });
    this.props.onRenameSurvey(name);
  };

  onSaveButtonClick = () => {
    this.save();
  };

  save = () => {
    const { survey } = this.props;
    if (!survey.name) {
      this.setState({
        showRenameSurveyModal: true,
        saveAfterRename: true,
      });
      return;
    }
    this.props.onSaveSurvey();
  };

  checkQuestionTranslations = () => {
    this.props
      .getSurvey(this.props.survey.id, this.props.projectId, { silent: true })
      .then(() => {
        if (
          this.state.translationPollAttemptCount >= TRANSLATION_MAX_ATTEMPTS &&
          this.state.translationIntervalId
        ) {
          // Waited too long
          this.props.showNotification({
            title: 'Auto-Translation failed',
            level: 'warning',
            message: 'Some questions were not translated',
          });
          clearInterval(this.state.translationIntervalId);
          this.setState({
            translationIntervalId: null,
            translationPollAttemptCount: 0,
          });
        } else if (
          this.props.translationProgress === 100 &&
          this.state.translationIntervalId
        ) {
          this.props.showNotification({
            title: 'Auto-Translation finished',
            message: 'Please make sure to review the generated translations',
          });
          clearInterval(this.state.translationIntervalId);
          this.setState({
            translationIntervalId: null,
            translationPollAttemptCount: 0,
          });
        } else {
          // Try again
          this.setState({
            translationPollAttemptCount:
              this.state.translationPollAttemptCount + 1,
          });
        }
      });
  };

  handleTranslateSurvey = () => {
    this.props.translateSurvey(where({ id: this.props.survey.id })).then(() => {
      this.props.getSurvey(this.props.survey.id, this.props.projectId, {
        silent: true,
      });

      const intervalId = setInterval(
        this.checkQuestionTranslations,
        TRANSLATION_POLLING_INTERVAL
      );
      this.setState({
        translationIntervalId: intervalId,
        translationPollAttemptCount: 0,
      });
    });
  };

  renderAutoTranslateLabel = () => {
    if (this.state.translationIntervalId) {
      return `Translating (${this.props.translationProgress}%)`;
    }

    return 'Auto-Translate';
  };

  render() {
    const {
      survey,
      completeness,
      onLanguageChanged,
      renderRightAction,
      onSaveSurvey,
      onJumpToQuestion,
    } = this.props;

    let saveLabel = !survey.id ? 'Create New Survey' : 'Save Changes';
    if (!survey.dirty && !!survey.id) saveLabel = 'Survey saved';

    const popoverProps = {
      searchHint: 'Find question',
      large: true,
      style: { height: 600, maxHeight: 600 },
    };
    popoverProps.anchorOrigin = { horizontal: 'left', vertical: 'bottom' };
    popoverProps.targetOrigin = popoverProps.anchorOrigin;

    return (
      <Section type="sticky-footer">
        <Container horizontal nowrap>
          <Actions>
            {onLanguageChanged && survey.languages.length >= 1 && (
              <LanguageSelector
                completeness={completeness}
                activeLanguage={survey.activeLanguage}
                languages={survey.languages}
                onChange={onLanguageChanged}
              />
            )}
            {survey.questions.length > 3 && onJumpToQuestion && (
              <SelectPopoverLight
                label="Jump to question"
                popoverProps={popoverProps}
                renderItems={(search) =>
                  survey.questions
                    .filter((q) => q.question?.includes(search))
                    .map((q, i) => (
                      <SelectPopoverLight.MenuItem
                        key={i}
                        primaryText={q.question}
                        onClick={() => onJumpToQuestion(q.id)}
                      />
                    ))
                }
              />
            )}
            {this.props.survey.languages.length > 1 &&
              this.props.hasAutoTranslateButton && (
                <Button
                  size="l"
                  disabled={this.state.translationIntervalId}
                  secondary
                  label={this.renderAutoTranslateLabel()}
                  onClick={this.handleTranslateSurvey}
                />
              )}
          </Actions>
          <Actions>
            {renderRightAction && renderRightAction()}
            {!renderRightAction && onSaveSurvey && canManageSurvey() && (
              <Button
                size="l"
                disabled={!survey.dirty && !!survey.id}
                label={saveLabel}
                onClick={this.onSaveButtonClick}
              />
            )}
          </Actions>
        </Container>
        <RenameSurveyModal
          open={this.state.showRenameSurveyModal}
          name={survey.name}
          onClose={this.onRenameSurveyModalClose}
          onSave={this.onRenameSurvey}
        />
      </Section>
    );
  }
}
