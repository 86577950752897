import React from 'react';
import cls from './ProgressBar.module.css';
import { Icon } from 'im/ui/Icon/Icon';
import owlImg from 'src/static/owl.svg';
import PropTypes from 'prop-types';

export const ProgressBar = ({ progress, tip }) => {
  return (
    <div className={cls.progressBar}>
      <div className={cls.progressBarFill} style={{ width: `${progress}%` }} />
      <Icon tip={tip}>
        <img
          className={cls.progressBarIcon}
          alt="In progress"
          width={24}
          src={owlImg}
        />
      </Icon>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  tip: PropTypes.string,
};

export default ProgressBar;
