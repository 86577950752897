/*eslint no-undef: 0*/
import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  getSurvey,
  resetSurvey,
} from 'src/actionCreators/surveyActionCreators';
import surveyApi from 'src/api/Survey';
import Page from 'src/components/Page/Page';
import { where } from 'im/api/Query';
import Builder from 'src/pages/App/Analysis/Surveys/Survey/Builder/Builder';
import Overview from 'src/pages/App/Analysis/Surveys/Survey/Overview/Overview';
import Preview from 'src/pages/App/Analysis/Surveys/Survey/Preview/Preview';
import Responses from 'src/pages/App/Analysis/Surveys/Survey/Responses/Responses';
import Response from 'src/pages/App/Analysis/Surveys/Survey/Responses/Response';
import Share from 'src/pages/App/Analysis/Surveys/Survey/Share/Share';
import Summary from 'src/pages/App/Analysis/Surveys/Survey/Summary/Summary';
import { LionessProvider } from 'lioness';
import { messages } from 'src/../i18n/index';
const getGroupForTag = (tag) => tag?.tag_categories?.[0] ?? {};
const mapWithGroup = (array) =>
  array?.map((t) => ({ ...t, tagGroup: getGroupForTag(t) })) || [];

@connect(pick(['survey', 'surveyJsonapi', 'projectTagsRecent', 'project']), {
  getSurvey,
  resetSurvey,
  getSurveyJsonapi: surveyApi.find,
})
export default class SurveyPage extends React.PureComponent {
  static propTypes = {
    ...ReactRouterPropTypes,
    survey: PropTypes.object,
    project: PropTypes.object,
    surveyJsonapi: PropTypes.object,
    projectTagsRecent: PropTypes.object,
    getSurvey: PropTypes.func,
    resetSurvey: PropTypes.func,
    getSurveyJsonapi: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      tagsWithTagGroups: this.getTagsWithTagGroups(
        props.surveyJsonapi,
        props.projectTagsRecent
      ),
    };
  }
  componentWillUnmount() {
    this.props.resetSurvey();
  }
  componentDidMount() {
    if (this.props.match.params.surveyId !== 'new') {
      this.props.getSurvey(
        this.props.match.params.surveyId,
        this.props.match.params.projectId
      );
      this.getSurveyJsonapi(this.props.match.params.surveyId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.surveyJsonapi.data.survey_question_tags !==
        this.props.surveyJsonapi.data.survey_question_tags ||
      nextProps.projectTagsRecent.data !== this.props.projectTagsRecent.data
    ) {
      this.setState({
        tagsWithTagGroups: this.getTagsWithTagGroups(
          nextProps.surveyJsonapi,
          nextProps.projectTagsRecent
        ),
      });
    }

    // Fetch survey tags when changed
    if (
      nextProps.surveyJsonapi.data.survey_question_tags &&
      !nextProps.surveyJsonapi.pending.ui &&
      !nextProps.survey.dirty &&
      nextProps.survey.questions !== this.props.survey.questions
    ) {
      const isChanged = nextProps.survey.questions.some((q) =>
        (q.tags || []).some(
          (tagId) =>
            !nextProps.surveyJsonapi.data.survey_question_tags.some(
              (t) => t.id === tagId
            )
        )
      );
      if (isChanged) this.getSurveyJsonapi(nextProps.survey.id);
    }
  }

  getTagsWithTagGroups = (surveyJsonapi, projectTagsRecent) => [
    ...mapWithGroup(surveyJsonapi.data.survey_question_tags),
    ...mapWithGroup(projectTagsRecent.data),
  ];

  getSurveyJsonapi = (surveyId) =>
    this.props.getSurveyJsonapi(
      where({ id: surveyId })
        .include('survey_question_tags', 'survey_question_tags.tag_categories')
        .fields('survey', 'survey_question_tags', 'survey_logos')
        .fields('tag', 'tag_categories', 'title')
        .fields('tag_category', 'title')
    );

  get steps() {
    return [
      {
        id: 'overview',
        url: `${this.props.match.url}`,
        disabled: false,
        hasFooter: true,
        title: 'Overview',
        helpAppcue: '-LSzb4HkgqjoNTRzjmOh',
      },
      {
        id: 'design',
        url: `${this.props.match.url}/design`,
        disabled: this.isNew,
        hasFooter: true,
        title: 'Design',
        helpAppcue: '-LSzeJF68UboL9zNZL4W',
      },
      {
        id: 'preview',
        url: `${this.props.match.url}/preview`,
        disabled: this.isNew,
        hasFooter: true,
        title: 'Preview',
        helpAppcue: '-LSzpNfjhEJDLPi2UPPu',
      },
      {
        id: 'share',
        url: `${this.props.match.url}/share`,
        disabled: this.isNew,
        hasFooter: false,
        title: 'Share',
        badge: this.shareBadge,
        helpAppcue: '-LT-W-m0c2ioyJXuDr4c',
      },
      {
        id: 'responses',
        url: `${this.props.match.url}/responses`,
        disabled: this.isNew || !this.hasResponses,
        hasFooter: false,
        title: 'Responses',
        badge: this.responsesBadge,
      },
      {
        id: 'summary',
        url: `${this.props.match.url}/summary`,
        disabled: this.isNew || !this.hasResponses,
        hasFooter: false,
        title: 'Analysis',
      },
    ];
  }

  get activeStepId() {
    if (this.activeStepIndex === null) return null;
    return this.steps[this.activeStepIndex]?.id;
  }

  get activeStepIndex() {
    return this.steps
      .map((e) => e.url)
      .indexOf(
        this.props.location.pathname.replace(/responses\/\d+$/, 'responses')
      );
  }
  get isNew() {
    return !!this.props.match.url.match('/new');
  }
  get hasResponses() {
    return (
      true ||
      this.props.survey.finished_surveys_count > 0 ||
      this.props.survey.unfinished_surveys_count > 0
    );
  }
  get backUrl() {
    return this.props.match.url.replace(/[\w\d]+?$/, '/list');
  }
  get shareBadge() {
    return this.props.survey.sent_surveys_count;
  }
  get responsesBadge() {
    return this.props.survey.finished_surveys_count;
  }
  render() {
    return (
      <Page
        pending={
          this.props.survey.pending ||
          (this.props.match.params.surveyId !== 'new' && !this.props.survey.id)
        }
        title={this.props.survey.name || 'New survey'}
        back={this.backUrl}
        steps={this.steps}
        activeStep={this.activeStepIndex}
        hasFooter={this.steps[this.activeStepIndex]?.hasFooter}
      >
        <LionessProvider
          messages={messages}
          locale={this.props.survey.activeLanguage}
          debug={false}
        >
          <Switch>
            <Route
              path={`${this.props.match.path}/`}
              component={Overview}
              render={(props) => (
                <Overview {...props} activeStepId={this.activeStepId} />
              )}
              exact
            />
            <Route
              exact
              path={`${this.props.match.path}/design`}
              render={(props) => (
                <Builder
                  {...props}
                  tagsWithTagGroups={this.state.tagsWithTagGroups}
                  activeStepId={this.activeStepId}
                />
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/preview`}
              render={(props) => (
                <Preview
                  {...props}
                  tagsWithTagGroups={this.state.tagsWithTagGroups}
                  activeStepId={this.activeStepId}
                />
              )}
            />
            <Route
              path={`${this.props.match.path}/share`}
              render={(props) => (
                <Share {...props} activeStepId={this.activeStepId} />
              )}
              exact
            />
            <Route
              path={`${this.props.match.path}/responses/:granteeSurveyId`}
              render={(props) => (
                <Response
                  key={this.props.location.pathname}
                  {...props}
                  tagsWithTagGroups={this.state.tagsWithTagGroups}
                  activeStepId={this.activeStepId}
                />
              )}
            />
            <Route
              path={`${this.props.match.path}/responses`}
              render={(props) => (
                <Responses {...props} activeStepId={this.activeStepId} />
              )}
              exact
            />
            <Route
              exact
              path={`${this.props.match.path}/summary`}
              render={(props) => (
                <Summary {...props} activeStepId={this.activeStepId} />
              )}
            />
            <Redirect from="*" to="/error/404" />
          </Switch>
        </LionessProvider>
      </Page>
    );
  }
}
