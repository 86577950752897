import { T } from 'lioness';
import PropTypes from 'prop-types';
import React from 'react';

import { Dialog, Button, Tooltip } from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import cls from './PrintWidget.module.css';

export class PrintWidget extends React.Component {
  static propTypes = {
    hasDialog: PropTypes.bool,
    dialog: PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
    iconProps: PropTypes.object,
    children: PropTypes.node,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
  };

  static defaultProps = {
    dialog: {},
    tooltip: 'Print',
  };

  state = {
    isDialogOpen: false,
  };

  onPrintClick = () => {
    const { hasDialog } = this.props;
    if (!hasDialog) {
      return this.onPrintWindowRequestOpen();
    }

    return this.setState({ isDialogOpen: true }, () => {
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    });
  };

  onDialogRequestClose = () => {
    this.setState({ isDialogOpen: false }, () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  };

  onPrintWindowRequestOpen = () => {
    this.setState({ isDialogOpen: false }, () => {
      window.print();
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  };

  render() {
    const { dialog, hasDialog, children, iconProps } = this.props;

    const { isDialogOpen } = this.state;

    return (
      <div className={cls.printWidget}>
        <Icon
          name="print"
          tip="Click to print"
          {...iconProps}
          onClick={this.onPrintClick}
        />
        {!hasDialog ? null : (
          <Dialog
            title={dialog.title}
            leftActions={
              <Button
                label={<T context="button">Cancel</T>}
                negative
                onClick={this.onDialogRequestClose}
              />
            }
            rightActions={
              <Button
                label={dialog.buttonText}
                onClick={this.onPrintWindowRequestOpen}
              />
            }
            open={isDialogOpen}
            onRequestClose={this.onDialogRequestClose}
          >
            {children}
          </Dialog>
        )}
        <Tooltip />
      </div>
    );
  }
}

export default PrintWidget;
