import React from 'react';
import PropTypes from 'prop-types';
import OwlImg from 'src/static/owl.svg';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import {
  Card,
  Container,
  Button,
  Section,
  CardEmpty,
} from 'src/components/IMUI';
import cls from './PdfLoadError.module.css';

const PdfLoadError = ({ onRefresh }) => (
  <Section horizontal sunken grow className={cls.tagEditorPdfErrorWrapper}>
    <FlexColumn style={{ position: 'relative', height: '100%' }}>
      <Card className={cls.tagEditorPdfErrorCard} noPadding grow>
        <CardEmpty
          large
          style={{ width: '100%' }}
          title="PDF loading failed"
          description="Please try again by refreshing the page"
        >
          <Container className={cls.tagEditorPdfErrorContainer}>
            <img src={OwlImg} alt="refresh the page" />
            <Button size="l" secondary label="Refresh" onClick={onRefresh} />
          </Container>
        </CardEmpty>
      </Card>
    </FlexColumn>
  </Section>
);

PdfLoadError.propTypes = { onRefresh: PropTypes.func.isRequired };
export default PdfLoadError;
