import classNames from 'classnames/bind';
import { T, withTranslators } from 'lioness';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import pick from 'ramda/src/pick';
import React from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  clearPublicSurvey,
  setSurveyLanguage,
} from 'src/actionCreators/surveyAnswersActionCreators';
import surveyPublicApi from 'src/api/SurveyPublic';
import { Button, Container, Dialog, Section } from 'src/components/IMUI';
import SurveyLegalInfo from 'src/components/Survey/SurveyLegalInfo';
import { getSurveyPreferredLanguage } from 'src/utils/surveysI18n';
import { where } from 'im/api/Query';
import { NotificationsWidget } from 'im/ui/Notifications';
import TextWithClipboard from '../App/Analysis/Surveys/Survey/components/TextWithClipboard';
import CompanyLogo from './components/CompanyLogo';
import { SurveyAlert } from './components/SurveyAlert';
import SurveyContainer from './components/SurveyContainer';
import SurveyLanguageSelector from './components/SurveyLanguageSelector';
import surveyExpired from 'src/static/sunset.png';
import surveyIcon from 'src/static/surveyAnswer.png';
import cls from './PublicSurvey.module.css';
import Storage from 'src/services/Storage';

const cx = classNames.bind(cls);

export const getSurveyLanguage = (
  preferredLanguage,
  languages = [],
  apiLanguage = ''
) => {
  if (!languages || !languages.length) {
    return null;
  }
  if (languages.indexOf(preferredLanguage) > -1) {
    return preferredLanguage;
  }
  return languages.indexOf(apiLanguage) > -1 ? apiLanguage : languages[0];
};

@connect(pick(['surveyAnswers', 'notifications', 'surveyPublic']), {
  findSurvey: surveyPublicApi.find,
  startSurvey: surveyPublicApi.start,
  clearPublicSurvey,
  setSurveyLanguage,
})
class PublicSurvey extends React.Component {
  static propTypes = {
    ...ReactRouterPropTypes,
    surveyPublic: PropTypes.object,
    surveyAnswers: PropTypes.object,
    notifications: PropTypes.array,
    startSurvey: PropTypes.func,
    findSurvey: PropTypes.func,
    clearPublicSurvey: PropTypes.func,
    setSurveyLanguage: PropTypes.func,
    t: PropTypes.func,
    tp: PropTypes.func,
  };

  state = {
    isAlertOpen: false,
    confirmStartNewOpen: false,
    savedPermalink: null,
  };

  componentDidMount() {
    this.props.clearPublicSurvey();
    this.props.findSurvey({ public_id: this.props.match.params.publicId });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.surveyPublic.data !== this.props.surveyPublic.data) {
      this.handleLanguageChange({
        value: getSurveyLanguage(
          getSurveyPreferredLanguage(),
          nextProps.surveyPublic.data.languages
        ),
      });
      const savedPermalink = Storage.getItem(
        `permalink-${nextProps.surveyPublic.data.public_id}`
      );
      this.setState({ isAlertOpen: !!savedPermalink, savedPermalink });
    }
  }

  getSurveyName() {
    const { surveyPublic, surveyAnswers } = this.props;
    const { activeLanguage } = surveyAnswers;
    const { i18n, name, title } = surveyPublic.data;
    return (
      (i18n &&
        i18n[activeLanguage] &&
        (i18n[activeLanguage].name || i18n[activeLanguage].title)) ||
      name ||
      title
    );
  }

  handleStartSurvey = () => {
    this.state.savedPermalink
      ? this.setState({ confirmStartNewOpen: true })
      : this.forceStartSurvey();
  };

  forceStartSurvey = () => {
    // pass the batch identifier from search query (batch=:id)
    const { location } = this.props;
    const batch = queryString.parse(location.search).batch;
    this.props.startSurvey(
      where({ public_id: this.props.match.params.publicId }, { batch })
    );
  };

  handleLanguageChange = ({ value: language }) => {
    this.props.setSurveyLanguage(language);
    setTimeout(() => this.forceUpdate(), 500);
  };

  onAlertClose = () => {
    this.setState({ isAlertOpen: false });
  };

  render() {
    if (
      this.props.surveyPublic.pending.init ||
      !this.props.surveyPublic.data.meta
    )
      return null;
    const { notifications, surveyPublic, surveyAnswers, t, tp } = this.props;
    const { savedPermalink } = this.state;
    const { meta } = this.props.surveyPublic.data;
    const deadline = surveyPublic.data.deadline;
    const isExpired = !surveyPublic.meta.canFillOut && deadline;
    const showDeadlineWarning = !isExpired && deadline;

    return (
      <SurveyContainer>
        {isExpired
          ? null
          : this.state.isAlertOpen && (
              <SurveyAlert
                permalink={savedPermalink}
                onClose={this.onAlertClose}
              />
            )}
        <Section surveyWidth>
          <Container centered>
            {surveyPublic.data.organization_logo && (
              <CompanyLogo
                isPending={surveyPublic.pending.ui}
                logo={meta.organization_logo_url}
              />
            )}
            <h1
              className={cx(cls.publicSurveyTitle, {
                [cls.publicSurveyTitleExpired]: isExpired,
              })}
            >
              {this.getSurveyName()}
            </h1>

            <img
              src={isExpired ? surveyExpired : surveyIcon}
              height={300}
              alt="Survey icon"
            />
            <p className={cls.publicSurveyText}>
              {isExpired ? (
                <span>
                  <T>
                    Unfortunately the deadline to fill out this survey has
                    passed
                  </T>
                </span>
              ) : (
                <span>
                  <T>You have been invited to complete this survey.</T>
                  <br />
                  <T context="start public survey">Begin when ready.</T>
                  <br />
                  <br />
                </span>
              )}

              {showDeadlineWarning && (
                <span>
                  <T
                    message="The deadline for answering the survey expires on {{ deadlineDate }}"
                    deadlineDate={new Date(deadline).toLocaleString()}
                  />
                  )
                </span>
              )}
            </p>
            {surveyPublic.data.languages?.length > 1 && (
              <SurveyLanguageSelector
                activeLanguage={surveyAnswers.activeLanguage}
                languages={surveyPublic.data.languages}
                onChange={this.handleLanguageChange}
              />
            )}
            <br />
            {isExpired
              ? null
              : savedPermalink && (
                  <div>
                    <Button
                      href={`/answer-survey/${savedPermalink}`}
                      size="l"
                      label={t('Continue from saved copy')}
                    />{' '}
                    <br />{' '}
                  </div>
                )}
            {isExpired ? null : (
              <Button
                size="l"
                label={
                  savedPermalink
                    ? tp('button', 'Start new')
                    : tp('button', 'Begin survey')
                }
                secondary={!!savedPermalink}
                disabled={isExpired}
                onClick={this.handleStartSurvey}
              />
            )}

            <SurveyLegalInfo
              hasAddress
              spaceTop
              className={cx('extraPaddingTop')}
              organizationTitle={meta.organization_title}
              organizationEmail={meta.organization_contact_email}
            />
          </Container>

          <Dialog
            small
            light
            open={this.state.confirmStartNewOpen}
            title={tp('dialog title', 'Confirm starting new survey.')}
            leftActions={
              <Button
                size="l"
                secondary
                onClick={() => this.setState({ confirmStartNewOpen: false })}
                label={tp('button', 'Cancel')}
              />
            }
            rightActions={
              <Button
                size="l"
                onClick={this.forceStartSurvey}
                label={tp('button', 'Confirm')}
              />
            }
          >
            <p>
              <T
                context="dialog warning"
                message="Starting new survey will overwrite the saved one. If you have not written down your survey's personal link you won't be able to access it anymore."
              />
            </p>
            <TextWithClipboard
              notificationMsg="Survey's personal link copied"
              label={`${window.location.origin}/answer-survey/${savedPermalink}`}
              tip={t("Copy your survey's personal link")}
            />
            <p>
              <T message="By chosing confirm you will begin a new survey." />
            </p>
          </Dialog>
          <NotificationsWidget notifications={notifications} />
        </Section>
      </SurveyContainer>
    );
  }
}

export default withTranslators(PublicSurvey);
