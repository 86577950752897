import {
  PROJECT__CREATE_SUCCESS,
  PROJECT__UPDATE_SUCCESS,
  PROJECT__DELETE_SUCCESS,
  PROJECT__ACTIVE_CHANGE,
  PROJECT__GET_INIT_SUCCESS,
  PROJECT__GET_INIT_REQUEST,
} from 'src/constants';
import {
  getInitProject,
  getProjects,
} from 'src/actionCreators/projectActionCreators';
import { surveyOnly } from 'src/userStorage';
import history from 'src/historyInstance';

export default function projectEffects(_state, action, dispatch) {
  switch (action.type) {
    case PROJECT__GET_INIT_REQUEST:
      return !action.meta?.skipReloadProjects ? null : dispatch(getProjects());

    case PROJECT__GET_INIT_SUCCESS:
      return action.meta?.doRedirect
        ? history.push(
            action.meta?.path || `/analysis/${action.payload.id}/summary`
          )
        : null;

    case PROJECT__UPDATE_SUCCESS:
      return action.payload.noSideEffect
        ? null
        : history.push(`/analysis/${action.payload.data?.id}/summary`);

    case PROJECT__DELETE_SUCCESS:
      return history.push('/analysis');

    case PROJECT__CREATE_SUCCESS:
      return dispatch(
        getInitProject(action.payload.id, {
          skipReloadProjects: false,
          doRedirect: true,
          path: surveyOnly()
            ? `/analysis/${action.payload.id}/surveys/new`
            : action.payload.data?.grantee_type === 'organization'
            ? `/analysis/${action.payload.id}/edit?show_reminder=true`
            : `/analysis/${action.payload.id}/summary`,
        })
      );

    case PROJECT__ACTIVE_CHANGE:
      return dispatch(
        getInitProject(action.payload.id, {
          skipReloadProjects: true,
          doRedirect: true,
          path: action.payload.path || `/analysis/${action.payload.id}/summary`,
        })
      );

    default:
      break;
  }
}
