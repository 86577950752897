import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';
import Question from 'src/components/Survey/Question';
import { Container, Progress } from 'src/components/IMUI';
import { Icon } from 'im/ui';
import cls from './QuestionPreview.module.css';
import { getHeaderDetailsForType } from './QuestionEdit';
const cx = classNames.bind(cls);

export default class QuestionPreview extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    question: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    tagsWithTagGroups: PropTypes.array.isRequired,
    index: PropTypes.number,
  };

  static defaultProps = { onClick: () => void 0 };

  onClick = () => {
    this.props.onClick(this.props.question, this.props.index);
  };

  render() {
    const {
      className,
      question,
      selected,
      disabled,
      tagsWithTagGroups,
      index,
    } = this.props;
    const questionInfo = getHeaderDetailsForType(this.props.question.type);
    return (
      <div
        onClick={this.onClick}
        className={cx(
          'questionPreviewWrapper',
          { selected, disabled, isLayout: question.isLayout },
          className
        )}
      >
        <Container className={cls.header} horizontal>
          <div className={cls.title}>
            <Icon name={questionInfo.icon} />
            {questionInfo.title}
          </div>
          {question.translation_status === 'processing' && (
            <div className={cls.title}>
              Translation in Progress
              <CircularProgress
                style={{ marginLeft: 8 }}
                thickness={2}
                size={12}
              />
            </div>
          )}
        </Container>
        <Question
          question={question}
          tagsWithTagGroups={tagsWithTagGroups}
          index={index}
        />
      </div>
    );
  }
}
